import { ContactsOutlined } from "@ant-design/icons";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";
import { useIPFS } from "./useIPFS";


// pass an NFT address and token id and return the NFT details

 export const useNFTDetails = (addr, id) => {
  const { token } = useMoralisWeb3Api();
  const { chainId } = useMoralisDapp();
  const [NFTDetails, setNFTDetails] = useState([]);
  const { resolveLink } = useIPFS();
  const [fetchSuccess, setFetchSuccess] = useState(true);

  const {
    // fetch: getNFTDetails,
    data,
    // error,
    // isLoading,
  } = useMoralisWeb3ApiCall(token.getTokenIdMetadata, {
    address: addr,
    token_id: id,                                
    chain: chainId|| "0x89",
  });

  useEffect(async () => {
    if (data) {
      const NFT = {...data};
      setFetchSuccess(true);
      if (NFT?.metadata) {
        NFT.metadata = JSON.parse(NFT.metadata);
        //if (NFT.metadata?.attributes[0].trait_type == 'Project'){NFT.project = NFT.metadata?.attributes[0].value;}else{NFT.project ='lighthouse';}
        if (NFT.metadata?.attributes[0] == null){NFT.project = 'lighthouse';}else  {      if (NFT.metadata?.attributes[0].trait_type == 'Project'){NFT.project = NFT.metadata?.attributes[0].value;}else{NFT.project ='lighthouse';}}
        if (NFT.metadata?.image){NFT.image = resolveLink(NFT.metadata?.image);}
        if (NFT.metadata?.animation_url){NFT.animation_url = resolveLink(NFT.metadata?.animation_url);}
      } else if (NFT?.token_uri) {
        try {
          await fetch(NFT.token_uri)
            .then((response) => response.json())
            .then((data) => {
              NFT.image = resolveLink(data.image);
            });
        } catch (error) {
          setFetchSuccess(false);
        }
      }
      setNFTDetails(NFT);
    }
  }, [data]);



  return {
    // getNFTDetails,
    NFTDetails,
    // fetchSuccess,
    // error,
    // isLoading,
  };
};
