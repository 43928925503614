import { Select } from 'antd';
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import {Link} from 'react-router-dom';
import { useAllUsers } from "../../../hooks/useAllUsers"
import { useProyectos } from "../../../hooks/useProyectos"

function SearchProject({ fn }){
    const { Option } = Select;
    const { chainId } = useMoralisDapp();
    const { Users } = useAllUsers();
    const { Proyectos } = useProyectos();
    console.log(Proyectos);
    console.log("aca4");

    //let projects = Users.filter((U) => U.attributes.isProject )
    let projects = Proyectos;
    const onChange = (e) => {
        fn(e)
    }
    return (
        <>
        {/* <div className="header__search">
            <input type="text" placeholder="Search" />
            <Link to="no-results" className="header__result">
                <i className="ri-search-line" />
            </Link>
        </div> */}

        <Select
            showSearch
            // style={{width: "300px",
            //         marginLeft: "20px" }}
            className='header__search'
            bordered={true}
            placeholder="Select a project"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            onChange={onChange}
        >   
        {projects && 
            projects.map((project, i) => 
            <Option value={project.get("ethAddress")} key= {i}>
                    {project.get("username")}
            </Option>
            )
            }   
        </Select>
            
        </>
    )
}
export default SearchProject;