import React from 'react';
import CardUser from '../../../components/cards/CardUser';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroUser from '../../../components/hero/HeroUser';
import SidebarUser from '../../../components/sidebars/SidebarUser';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import CollectionUser from '../../../components/collection/CollectionUser';
import ProyectosUser from '../../../components/collection/ProyectosUser';
import useDocumentTitle from '../../../components/useDocumentTitle';
import {Link} from 'react-router-dom';
import { Redirect, useParams } from 'react-router-dom';
import { isURLRequestable } from 'css-loader/dist/utils';
import { useUser } from "../../../hooks/useUser";
import { useAccount } from 'wagmi';

const User = () => {
  useDocumentTitle('User ');
  const { isConnected: isAuthenticated, address: userAddress } = useAccount();
  var { address } = useParams();
  address = address.toLowerCase()
  const { User } = useUser(address);
  const user1 = User[0];
  

  function equals() {
    return (userAddress?.toUpperCase() === address.toUpperCase());
  } 

  return (
    <>
      <Header/>
      { (isAuthenticated && equals()) && (
        <Redirect to="/profile" />
      )}
        <div>
          <HeroUser address={address} />
           <div className="container">
              <div className="row justify-content-center">
                  <SidebarUser address={address} />
              </div>
              <div className="row justify-content-center">
                <div className="profile__content">
                  <div className="justify-content-between">
                    <Tabs className="space-x-10">
                      <div className="d-flex justify-content-between">
                        <TabList className="d-flex space-x-10 mb-30 nav-tabs">
                          <Tab className="nav-item">
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-1"
                              role="tab">
                              Colecciones
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-2"
                              role="tab">
                              Creaciones
                            </Link>
                          </Tab>
                          {!user1?.get("isProject") && (
                            <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-3"
                              role="tab">
                              Proyectos
                            </Link>
                          </Tab>
                            )}
                        </TabList>

                      </div>
                      <div className="tab-content">
                        <TabPanel className="active">
                          <CollectionUser address={address}/>
                        </TabPanel>
                        <TabPanel>
                          <CardUser address={address} />
                        </TabPanel>
                        {!user1?.get("isProject") && (
                           <TabPanel>
                            <ProyectosUser address={address} />
                           </TabPanel>
                            )}
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          <Footer />
        </div>
    </>
  );
};

export default User;
