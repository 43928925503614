import {React, Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'

const SidebarCollection = ({Collection}) => {

  const [showMore, setShowMore] = useState(false);

  return (
    <Fragment >
    <div className='container mt-40'>
    <div className='col-lg-8 col-md-8 col-sm-12'>
      <div className='col-lg-12 col-md-8 col-sm-12'>
        <div className="space-y-10">
        <div className="space-y-20">
          <p className='txt'>
            {!Collection && <Skeleton count={2} />}
            {Collection?.get("description")?.length > 200? (
              <Fragment>
                {!showMore ? (
                <Fragment>
                  {Collection?.get("description")?.slice(0, 200)}...
                  <Link to="#" className='txt ' onClick={() => {setShowMore(true)}}>
                    Ver mas
                    <i className="ri-arrow-down-s-line"></i>
                  </Link>
                </Fragment>
              ):(
                <Fragment>
                  {Collection?.get("description")}
                  <Link to="#"  className='txt ' onClick={() => {setShowMore(false)}}>
                  Ver menos
                  <i class="ri-arrow-up-s-line"></i>
                </Link>
              </Fragment>
              )}
              </Fragment>
            ):(
              Collection?.get("description")
            )
            }
          </p>
        </div>
        </div>
      </div>

    </div>
    
    {/* <div className='col-lg-4 col-md-4 col-sm-12 mb-40'>
      <div className="space-y-10">
        <div className="box">
          <ul className="social_profile space-y-10 overflow-hidden">
            <li>
            <a href={`https://www.facebook.com/${Collection?.get("socialLinks")?.facebook}`} rel="noreferrer"  target="_blank">
                <i className="ri-facebook-line" />
                <span className="color_text">facebook/</span>
                {Collection?.get("socialLinks")?.facebook}
              </a>
            </li>
            <li>
            <a href={`https://www.instagram.com/${Collection?.get("socialLinks")?.instagram}`} rel="noreferrer"  target="_blank">
                <i className="ri-instagram-line" />
                <span className="color_text">instagram/</span>
                {Collection?.get("socialLinks")?.instagram}
              </a>
            </li>
            <li>
            <a href={`https://www.twitter.com/${Collection?.get("socialLinks")?.twitter}`} target="_blank" rel="noreferrer" >
                <i className="ri-twitter-line" />
                <span className="color_text">twitter/</span>
                {Collection?.get("socialLinks")?.twitter}
              </a>
            </li>
            <li>
            <a href={`https://discord.com/invite/${Collection?.get("socialLinks")?.discord}`}  target="_blank" rel="noreferrer" >
                <i className="ri-discord-line" />
                <span className="color_text">discord/</span>
                {Collection?.get("socialLinks")?.discord}
              </a>
            </li>
            <li>
            <a href={`https://www.youtube.com/${Collection?.get("socialLinks")?.youtube}`} target="_blank" rel="noreferrer" >
                <i className="ri-youtube-line" />
                <span className="color_text">youtube/</span>
                {Collection?.get("socialLinks")?.youtube}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> */}
    </div>
 
</Fragment>
  );
};

export default SidebarCollection;
