import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";

// given an address of a user/project it returns the collections he is part from

export const useCollectionUser = (address, pageSize = 10000) => {
  const [Collections, setCollections] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const {
    // fetch: getNFTDetails,
    data,
    isFetching,
    // error,
    // Reference: https://www.npmjs.com/package/react-moralis#usemoralisquery
  } = useMoralisQuery("Collection", query => query.skip(page * pageSize).limit(pageSize), [page]);

  const isLastPage = data && data.length < pageSize;

  const nextPage = () => {
    if (!isLastPage) {
      setPage(page => page + 1);
      setIsLoading(true);
    }
  };
  
  useEffect(async () => {
    if (data) {
        var collections = [];
        for (let i of data){
            for (let j of i.get("co_creators")){
                if ( j.address.toLowerCase() == address.toLowerCase()){
                    collections.push(i);
                }
            }
        }
      setFetchSuccess(true);
      setCollections(prev => [...prev, ...collections]);
      setIsLoading(false);
    }
  }, [data]);



  return {
    Collections,
    fetchSuccess,
    isLoading: isLoading || isFetching,
    nextPage,
    isLastPage,
  };
};
