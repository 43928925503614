import React, { Component, Fragment } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from './Menu/MobileMenu';
import MegaMenu from './Menu/MegaMenu';
import Explore from './Menu/Explore';
import Account from "./Menu/Account";
import SearchCollections from './Menu/SearchCollections';
import ReactSwitch from "react-switch";
import { Modal, Button } from 'antd'
const HomesMenu = [
  {
    icon: 'home-smile-2',
    title: ' Home page 1',
    link: '/home-1',
  },
  {
    icon: 'home-2',
    title: ' Home page 2',
    link: '/home-2',
  },
  {
    icon: 'home-5',
    title: ' Home page 3',
    link: '/home-3',
  },
];

const PagesMenu = [
  // {
  //   icon: 'home-smile-2',
  //   title: ' Home',
  //   link: '/',
  // },
  // {
  //   title: 'Marketplace',
  //   link: '/marketplace',
  // },
  // {
  //   title: 'Colecciones',
  //   link: '/collections',
  // },
  {
    title: 'Proyectos',
    link: '/proyectos',
  },
  // {
  //   title: ' Crear',
  //   link: '/upload-type',
  // },
  // {
  //   title: ' Perfil',
  //   link: '/profile',
  // },
];


const Header = () => {
  const [isActive, setActive] = useState(false);
  const [MetamaskModal, setMetamaskModal] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };
  var requireMetamask = (!window.ethereum);
  if (requireMetamask) {
    var requireMetamask = localStorage.getItem('require_metamask')
    if (requireMetamask == null) {
      localStorage.setItem('require_metamask', true)
      setMetamaskModal(true);
    }
  }

  return (
    <div>
      <Modal
        title="Conecta tu billetera con Metamask!"
        open={MetamaskModal}
        // onCancel={() => console.log('')}
        width="400px"
        heigth="400px"
        footer="Si ya tienes Metamask habilita el acceso a esta pagina."
        onCancel={() => {
          localStorage.setItem('require_metamask', false)
          setMetamaskModal(false);
          console.log('cancel');
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="btn btn-lg"
            style={{ backgroundColor: "orange", border: "none" }}
            onClick={() =>
              window.open("https://metamask.io/download/", "_blank")
            }
          >
            Descargar
            <img
              width="24"
              heigth="24"
              src="/img/icons/metamask.svg"
              alt="metamask-icon"
            ></img>
          </Button>
        </div>
      </Modal>

      <header className="header__1">
        <div className="container">
          <div className="wrapper js-header-wrapper">
            <div className="header__logo">
              <Link to="/">
                <img
                  className="header__logo"
                  id="logo_js"
                  src="/img/logos/Logo.png"
                  alt="logo"
                // style={{width : "200px" , height : "70px"}}
                />
              </Link>
            </div>
            {/* ==================  */}
            <div className="header__menu">
              <ul className="d-flex space-x-20">

                {PagesMenu.map((val, i) => (
                  <li key={i}>
                    <Link className="color_black" to={val.link}>
                      {val.title}
                    </Link>
                  </li>
                ))}

                <li className="has_popup2">
                  <Link className="color_black hovered" to="/collections">
                    Explorar
                  </Link>
                  <ul className="menu__popup2 space-y-20">
                    <Explore />
                  </ul>
                </li>

                <li className="has_popup2">
                  <Link className="color_black hovered" to="/">
                    Mas <i className="ri-more-2-fill" />
                  </Link>
                  <ul className="menu__popup2 space-y-20">
                    <MegaMenu />
                  </ul>
                </li>
              </ul>
            </div>
            {/* ================= */}
            <SearchCollections />
            {/* <Switch onChange={this.handleChange} checked={this.state.checked} /> */}
            <Account />
            <div className="header__burger js-header-burger" onClick={toggleClass} />
            <div className={` header__mobile js-header-mobile  ${isActive ? 'visible' : null} `}>
              <MobileMenu />
            </div>
          </div>
        </div>
      </header>
    </div>

  );
};

export default Header;
