import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";

export const useCollection = (namePath) => {
  const [Collection, setCollection] = useState();
  const [fetchSuccess, setFetchSuccess] = useState(true);

  function getPath(name){
    var path = name.toLowerCase();
    path = path.replace(/ /g, "-");
    return path;
  }

  const {
    // fetch: getNFTDetails,
    data,
    // error,
    // isLoading,
  } = useMoralisQuery("Collection");
  



  useEffect(async () => {
    if (data) {
    for (let val of data){
        if (getPath(val.get("name")) == namePath){
            setCollection(val);
            setFetchSuccess(true);
        }
    }
    }
  }, [data]);



  return {
    // getNFTDetails,
    Collection,
    fetchSuccess,
    // error,
    // isLoading,
  };
};

//   bybg52089381
