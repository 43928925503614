import { React, useEffect, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import CardMarketplace from '../../../components/cards/CardCollection';
import CardMarketplaceOnSale from '../../../components/cards/CardCollectionOnSale';
import CardMarketplaceUtilities from '../../../components/cards/CardCollectionUtilities';
import { useMoralisQuery } from "react-moralis";
import env from "../../../providers/MoralisDappProvider/environment"


function MenuCategoriesMarket({Collection}) {
  const { ENDPOINT,SECRET_KEY,KEY,CLIENT_ID } = env;

  const [apiHitResponse, setApiHitResponse] = useState();
  const [MVCol, setMVCol] = useState()
  const [MFCol, setMFCol] = useState()
  const [MPCol, setMPCol] = useState()
  const [SECol, setSECol] = useState()
  const [EventosCol, setEventosCol] = useState()
  const [PromocionesCol, setPromocionesCol] = useState()

  const [DaypassCol, setDaypassCol] = useState()
  const [MembresiaFlexibleQuincenalCol, setMembresiaFlexibleQuincenalCol] = useState()
  const [SalaPrivadaPorHoraCol, setSalaPrivadaPorHoraCol] = useState()
  const [SalaPrivadaPorDiaCol, setSalaPrivadaPorDiaCol] = useState()
  const [SalaPrivadaPorMesCol, setSalaPrivadaPorMesCol] = useState()
    
  // const [EVCol, setEVCol] = useState()
  // const [EFCol, setEFCol] = useState()
  // const [SRCol, setSRCol] = useState()
  //const [SSCol, setSSCol] = useState()
  //const [SEventosCol, setSEventosCol] = useState()
  const [apiHitResponseSuccess, setApiHitResponseSuccess] = useState(false);


  async function fetchUtilities(id, address) {
    //var url = 'https://lighthousetest.powerfy.cloud:5000/get_nft?client_id=23yxAT7QDSHr&secret_key=cb5064LRvJkvICip&api_key=mOAVSBejeqgsl1dTKF2sM6oJrS4xc1uV&code=' + address + '-' + id
    let url = `${ENDPOINT}/get_nft?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&code=${address}-${id}`
    
    let response = "";
    response = await fetch(url)
      .then(resp => resp.json())
      .then(resp => {return resp})
      .catch(err => console.log(err)) 
    return response
  }

  useEffect(async () => {
    if (apiHitResponseSuccess || !Collection) return;
    const colResponses = await Collection?.attributes.items.map(async element => {
      const address = element.split('/')[0];
      const id = element.split('/')[1];
      return await fetchUtilities(id, address)
    });
    Promise.all(colResponses)
    .then((resp) => {
      setApiHitResponse(resp)
    })
    setApiHitResponseSuccess(true)
  }, [Collection])

  //require refactor.
  useEffect(()=>{
    if(!apiHitResponse) return;
    var membresiaViajero = [];
    var membresiaFlexible = [];
    var membresiaPremium = [];
    var salasEspeciales = [];
    var eventos = [];
    var promocionales = [];

    var daypass = [];
    var membresiaFlexibleQuincenal = [];
    var salaPrivadaPorHora = [];
    var salaPrivadaPorDia = [];
    var salaPrivadaPorMes = [];

    // var escritorioVariable = [];
    // var escritorioFijo = [];
    // var salaDeReuniones = [];
    //var salaDeStreaming = [];
    //var salaDeEventos = [];
    for(let i = 0; i < apiHitResponse.length; i++ ) {
      if(apiHitResponse[i] && apiHitResponse[i].length !== 0){
        //console.log('POST IF')
        //console.log("api hit response", Object.entries(apiHitResponse[i][0]))
        Object.entries(apiHitResponse[i][0]).forEach((attributesAsTuple) => {
          switch (attributesAsTuple[0]) {
            case '5':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
                membresiaViajero.push(Collection.attributes.items[i])
              break;
            case '10':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
                membresiaFlexible.push(Collection.attributes.items[i])
              break;
            case '15':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              membresiaPremium.push(Collection.attributes.items[i])
              break;
            case '20':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              eventos.push(Collection.attributes.items[i])
              break;
            case '25':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              salasEspeciales.push(Collection.attributes.items[i])
              break;
            case '30':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              promocionales.push(Collection.attributes.items[i])
              break;
            case '32':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              daypass.push(Collection.attributes.items[i])
              break;
            case '11':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              membresiaFlexibleQuincenal.push(Collection.attributes.items[i])
              break;
            case '51':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              salaPrivadaPorHora.push(Collection.attributes.items[i])
              break;
            case '75':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              salaPrivadaPorDia.push(Collection.attributes.items[i])
              break;
            case '80':
              if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
              salaPrivadaPorMes.push(Collection.attributes.items[i])
              break;
          
              // case '35':
            //   if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
            //   escritorioVariable.push(Collection.attributes.items[i])
            //   break;
            // case '40':
            //   if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
            //   escritorioFijo.push(Collection.attributes.items[i])
            //   break;
            // case '45':
            //   if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
            //   salaDeReuniones.push(Collection.attributes.items[i])
            //   break;
            //case '50':
            //  if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
            //  salaDeStreaming.push(Collection.attributes.items[i])
            //  break;
            //case '55':
            //  if(attributesAsTuple[1].used_quantity < attributesAsTuple[1].quantity)
            //  salaDeEventos.push(Collection.attributes.items[i])
            //  break;
            default: break;
          }
        })
      }
    } 
    setMVCol(membresiaViajero);
    setMFCol(membresiaFlexible);
    setMPCol(membresiaPremium);
    setSECol(salasEspeciales);
    setEventosCol(eventos);
    setPromocionesCol(promocionales);

    setDaypassCol(daypass);
    setMembresiaFlexibleQuincenalCol(membresiaFlexibleQuincenal);
    setSalaPrivadaPorHoraCol(salaPrivadaPorHora);
    setSalaPrivadaPorDiaCol(salaPrivadaPorDia);
    setSalaPrivadaPorMesCol(salaPrivadaPorMes);

    // setSSCol(salaDeStreaming);
    // setEVCol(escritorioVariable);
    // setEFCol(escritorioFijo);
    // setSRCol(salaDeReuniones);  
    // setSEventosCol(salaDeEventos);
  },[apiHitResponse])
  return (
    <div className="w-100">
          <div className="container">
            <div className="section mt-50">
              <div>
                {/* <h2 className="section__title mb-20"> Items</h2> */}
                <div>
                  <div>
                    <div className="align-items-center">
                      <Tabs>
                        <div className="row justify-content-between align-items-center section__head">
                          <div className="col-lg-auto overflow-x-scroll" id="custom-menuCategoriesCollection-1">
                            <TabList className="menu_categories menu_start" id="custom-menuCategoriesCollection-2">
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  All items
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  On Sale
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Membresia Viajero
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Membresia Flexible
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Membresia Premium
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Eventos
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Salas Especiales
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Promocionales
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Day Pass
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Membresia Flexible Quincenal
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Sala Privada Por Hora
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Sala Privada Por Dia
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Sala Privada Por Mes
                                </span>
                              </Tab>


                              {/* <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Escritorio Variable
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Escritorio Fijo
                                </span>
                              </Tab> */}
                              {/* <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Sala de reuniones
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Sala de streaming
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Sala de eventos
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Has list price
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Has open offer
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Owned by creator
                                </span>
                              </Tab>
                              <Tab className="switch_item">
                                <span className="btn btn-white btn-sm">
                                  Has sold
                                </span>
                              </Tab> */}
                            </TabList>
                          </div>
                        </div>

                        <TabPanel>
                          <CardMarketplace Collection={Collection} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceOnSale Collection={Collection} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={MVCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={MFCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={MPCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={EventosCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={SECol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={PromocionesCol} />
                        </TabPanel>

                        <TabPanel>
                          <CardMarketplaceUtilities items={DaypassCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={MembresiaFlexibleQuincenalCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={SalaPrivadaPorHoraCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={SalaPrivadaPorDiaCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={SalaPrivadaPorMesCol} />
                        </TabPanel>


                        {/* <TabPanel>
                          <CardMarketplaceUtilities items={EFCol} />
                        </TabPanel>
                        <TabPanel>
                          <CardMarketplaceUtilities items={EVCol} />
                        </TabPanel> */}
                        {/* <TabPanel>
                          <CardMarketplaceUtilities items={SRCol} />
                        </TabPanel> 
                        <TabPanel>
                          <CardMarketplaceUtilities items={SSCol} />
                        </TabPanel>*/}
                        {/* <TabPanel>
                          <CardMarketplaceUtilities items={SEventosCol} />
                        </TabPanel> */}
                        
                        {/*
                        <TabPanel>
                          <CardsOffer />
                        </TabPanel>
                        <TabPanel>
                          <CardsCrerators />
                        </TabPanel>
                        <TabPanel>
                          <CardsSold />
                        </TabPanel> */}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
}

export default MenuCategoriesMarket;
