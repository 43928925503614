import React, {useRef, useState} from 'react';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroEditProfile from '../../../components/hero/HeroEditProfile';
import useDocumentTitle from '../../../components/useDocumentTitle';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMoralisFile } from "react-moralis";
import { Redirect } from 'react-router-dom';
import { useUser } from "../../../hooks/useUser";
import { useAccount } from 'wagmi';


import {Link} from 'react-router-dom';

const EditProfile = () => {
  useDocumentTitle('Editar Perfil ');
  const { isConnected: isAuthenticated, address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const { saveFile, moralisFile } = useMoralisFile();
  const [username, setUsername] = useState('username');
  const [email, setEmail] = useState('mail@mail.com');
  const [description, setDescription] = useState('description');
  const [profilePic, setProfilePic] = useState('defaultpic');
  const [bannerPic, setBannerPic] = useState('defaultbanner');
  const [facebook, setFacebook] = useState('defaultfacebook');
  const [instagram, setInstagram] = useState('defaultinstagram');
  const [twitter, setTwitter] = useState('defaulttwitter');
  const [discord, setDiscord] = useState('defaultdiscord');
  const [youtube, setYoutube] = useState('defaultyoutube');



  const deleting = () => toast.error('Avatar delete in progress');
  const confirm = () => toast('Your email is verified');

  const update = () =>{ 
    if (user?.get("socialLinks")){
      var socialLinks = user?.get("socialLinks");
    }else{
      var socialLinks = {
        facebook: '',
        instagram: '',
        twitter: '',
        discord: '',
        youtube: '',
      }
    }
  

    if (username != 'username') {user?.set("username", username)};
    if (email != 'mail@mail.com') {user?.set("email", email)};
    if (description != 'description') {user?.set("description", description)};
    if (facebook != 'defaultfacebook') {socialLinks.facebook = facebook;};
    if (instagram != 'defaultinstagram') {socialLinks.instagram = instagram;};
    if (twitter != 'defaulttwitter') {socialLinks.twitter = twitter;};
    if (discord != 'defaultdiscord') {socialLinks.discord = discord;};
    if (youtube != 'defaultyoutube') {socialLinks.youtube = youtube;};

    user?.set("socialLinks", socialLinks);
    user?.save().then(
      () => {
        toast("Profile updated");
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  const uploadFile = (fileTarget) => {
    // save to moralis
    setProfilePic(fileTarget);
    saveFile("photo.jpg", fileTarget, {
      type: "image/png",
      onSuccess: (result) => {
      user?.set("profilePic", result);
      user?.save().then(
        () => {
          toast('Profile pic updated');
        },
        (error) => {
          toast.error(error.message);
        }
      );
    },
      onError: (error) => console.log(error),
    });
  };


  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  useDocumentTitle(' Edit Profile');

if (isAuthenticated) {
  return (
    <div className="edit_profile">
      <Header />
      <HeroEditProfile user={user} setBannerPic={setBannerPic} bannerPic={bannerPic}/>
      <div className="container">
        <div>
          <div className="avatars space-x-20 mb-30">
            <div id="profile-container">
              {profilePic == 'defaultpic' ? (
                  <img
                  id="profileImage"
                  src={user?.get("profilePic") ? user?.get("profilePic").url() : "img/avatars/avatar_3.png"}
                  alt="Avatar"
                  className="avatar avatar-lg border-0"
                  />
              ) : (
              <img
                id="profileImage"
                src = {URL.createObjectURL(profilePic)}
                alt="Avatar"
                className="avatar avatar-lg border-0"
              />)}
            </div>
            <div className="space-x-10 d-flex">
              <div id="boxUpload">
                <Link to="#" className="btn btn-dark">
                  Upload  Photo
                </Link>
                <input
                  id="imageUpload"
                  type="file"
                  name="profile_photo"
                  placeholder="Photo"
                  required
                  capture
                  onChange={(e) => uploadFile(e.target.files[0])}
                />
              </div>
              <ToastContainer position="bottom-right" />
            </div>
          </div>

          <div className="box edit_box col-lg-9 space-y-30">
            <div className="row sm:space-y-20">
              <div className="col-lg-6 account-info">
                <h3 className="mb-20">Account info 🍉</h3>
                <div className="form-group space-y-10 mb-0">
                  <div className="space-y-40">
                    <div className="space-y-10">
                      <span className="nameInput">Display name</span>
                       <input
                        id="txtUsername"
                        type="text"
                        defaultValue={user?.get("username")}
                        onChange={(e) => setUsername(e.target.value)}
                      />                
                    </div>
                    <div className="space-y-10">
                      <span className="nameInput d-flex justify-content-between">
                        Email
                        <span className="txt_xs">
                          Your email for marketplace notifications
                        </span>
                      </span>
                      <div className="confirm">
                        <input
                          id="txtEmail"
                          type="text"
                          placeholder="Add your email"
                          defaultValue={user?.get("email")}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div className="space-y-10">
                      <span className="nameInput">Custom URL</span>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="raroin.creabik.com/"
                      />
                    </div> */}
                    <div className="space-y-10">
                      <span className="nameInput">Bio</span>
                      <textarea
                        style={{minHeight: 110}}
                        placeholder="Add your bio"
                        defaultValue={user?.get("description")}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 social-media">
                <h3 className="mb-20">Your Social media</h3>
                <div className="form-group space-y-10">
                  <div className="space-y-40">
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Instagram</span>
                      <input
                        type="text"
                        placeholder={"instagram username"}
                        defaultValue={user?.get("socialLinks")?.instagram ? user?.get("socialLinks")?.instagram : ""}
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Twitter</span>
                      <input
                        type="text"
                        placeholder={"twitter username"}
                        defaultValue={user?.get("socialLinks")?.twitter ? user?.get("socialLinks")?.twitter : ""}
                        onChange={(e) => setTwitter(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Facebook</span>
                      <input
                        type="text"
                        placeholder={"facebook username"}
                        defaultValue={user?.get("socialLinks")?.facebook ? user?.get("socialLinks")?.facebook : ""}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Discord</span>
                      <input
                        type="text"
                        placeholder={"discord username or invitation code"}
                        defaultValue={user?.get("socialLinks")?.discord ? user?.get("socialLinks")?.discord : ""}
                        onChange={(e) => setDiscord(e.target.value)}
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <span className="nameInput mb-10">Youtube</span>
                      <input
                        type="text"
                        placeholder={"youtube username"}
                        defaultValue={user?.get("socialLinks")?.youtube ? user?.get("socialLinks")?.youtube : ""}
                        onChange={(e) => setYoutube(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr" />
            <div>
              <Link to="#" className="btn btn-primary" onClick={update}>
                Update Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}else{
    return <Redirect to="/" />
}
};

export default EditProfile;
