import {React, useRef} from 'react';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { getExplorer } from "../../helpers/networks";
import { useMoralisDapp } from "../../providers/MoralisDappProvider/MoralisDappProvider";
import { useWeb3ExecuteFunction } from "react-moralis";
import {useMoralis, useMoralisQuery, useNewMoralisObject } from "react-moralis";
import { useState } from 'react';
import { Card, Image, Tooltip, Modal, Input, Alert, Spin, Button, Badge } from "antd";
import { toast, ToastContainer } from 'react-toastify';


function CardMarketplace({allNFTs}) {

  const ref = useRef();
  const closeTooltip = () => ref.current.close();

  const [visible, setVisibility] = useState(false);
  const [nftToBuy, setNftToBuy] = useState(null);
  const [loading, setLoading] = useState(false);

  const contractProcessor = useWeb3ExecuteFunction();
  const { chainId, marketAddress, contractABI, walletAddress } = useMoralisDapp();
  const contractABIJson = JSON.parse(contractABI);
  const { Moralis } = useMoralis();

  const NFTsDetails = allNFTs;
  
  async function purchase() {
    setLoading(true);
    const tokenDetails = getMarketItem(nftToBuy);
    const itemID = tokenDetails.uid;
    const tokenPrice = tokenDetails.price;
    const ops = {
      contractAddress: marketAddress,
      functionName: "createMarketSale",
      abi: contractABIJson,
      params: {
        nftContract: nftToBuy.token_address,
        id: itemID,
      },
      msgValue: tokenPrice,
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        // console.log("success");
        setLoading(false);
        setVisibility(false);
        updateSoldMarketItem();
        succPurchase();
      },
      onError: (error) => {
        setLoading(false);
        failPurchase();
        console.log(error);
      },
    });
  }

  const handleBuyClick = (nft) => {
    setNftToBuy(nft);
    // console.log(nft.image);
    setVisibility(true);
  };

  function succPurchase() {
    // let secondsToGo = 5;
    // const modal = Modal.success({
    //   title: "Success!",
    //   content: `You have purchased this NFT`,
    // });
    // setTimeout(() => {
    //   modal.destroy();
    // }, secondsToGo * 1000);
    toast.success("You have purchased this NFT");
  }

  function failPurchase() {
    // let secondsToGo = 5;
    // const modal = Modal.error({
    //   title: "Error!",
    //   content: `There was a problem when purchasing this NFT`,
    // });
    // setTimeout(() => {
    //   modal.destroy();
    // }, secondsToGo * 1000);
    toast.error("There was a problem when purchasing this NFT");
  }

  async function updateSoldMarketItem() {
    const id = getMarketItem(nftToBuy).objectId;
    const marketList = Moralis.Object.extend("MarketItemCreated");
    const query = new Moralis.Query(marketList);
    await query.get(id).then((obj) => {
      obj.set("state", "1");
      obj.set("buyer", walletAddress);
      obj.save();
    });
  }

  // filter NFTs that are on sale
  const getMarketItem = (nft) => {
    const result = NFTsDetails?.find(
      (e) =>
        e.nftContract === nft?.token_address &&
        e.tokenId === nft?.token_id &&
        e.state === "0"      // State (0, 1, 2) = (For sale, Sold, Cancelled)
    );
    return result;
  };



  return (
    <div>
      <div className="row mb-30_reset">
        {NFTsDetails.map((nft, i) => (
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
            <div className="card__item four">
              <div className="card_body space-y-10">
                {/* =============== */}
                  {/* <div className="creators space-x-10">
                    <div className="avatars space-x-3">
                      <Link to="profile">
                        <img
                          src={`/img/avatars/avatar_1.png`}
                          alt="Avatar"
                          className="avatar avatar-sm"
                        />
                      </Link>
                      <Link to="profile">
                        <p className="avatars_name txt_xs">@</p>
                      </Link>
                    </div>
                  </div> */}
                <div className="card_head">
                <Link to={`/item-details/${nft.token_address}/${nft.token_id}`}>
                {nft.animation_url ? (
                  <video
                  // autoPlay
                  // loop
                  type="video/mp4"
                  style={{width: "100%"}}
                  className="item_img"
                  src= {nft?.animation_url}
                />
              ) : (
                <img
                className="item_img"
                src= {nft?.image}
                />  
                )}
              </Link>
                  <Link to="#" className="likes space-x-3">
                    <i className="ri-heart-3-fill" />
                    <span className="txt_sm"></span>
                  </Link>
                  {getMarketItem(nft) && (
                  <div className="action">
                    <button className="btn btn-sm btn-primary btn_auction" >
                        <i className="ri-auction-line color_white mr-5px" />
                        {getMarketItem(nft)?.price / 10 ** 6} USDC
                    </button>
                  </div>
                  )}
                </div>
                {/* =============== */}
                <div className="card_bottom">
                <h6 className="card_title">
                <Link className="color_black" to={`/item-details/${nft.token_address}/${nft.token_id}`}>
                  {nft?.metadata?.name ? (
                    nft?.metadata?.name.length > 25 ? (
                      nft.metadata.name.substring(0, 25) + "..."
                    ) : (
                      nft.metadata.name
                    )
                    ) : (
                      nft.symbol
                    )}
                </Link>
                </h6>
                <div className="card_footer d-block space-y-10">
                <div className=" d-flex justify-content-between align-items-center">
                  <div className="creators space-x-10">
                    <Link to="profile">
                      <p className="avatars_name txt_sm">
                        {nft?.token_id?.length > 7 ? (
                        "#" + nft.token_id.substring(0, 7) + "..."
                        ) : (
                        "#" + nft.token_id
                        )}
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="hr" />
                <div className="d-flex align-items-center space-x-10" onClick={() =>
                      window.open(
                        `${getExplorer(chainId)}address/${nft.token_address}`,
                        "_blank"
                      )
                    }>
                  <i className="ri-vip-crown-line" />
                      <p className="avatars_name txt_sm">
                        View on explorer
                      </p>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
            title={`Buy ${nftToBuy?.name} #${nftToBuy?.token_id}`}
            visible={visible}
            onCancel={() => setVisibility(false)}
            onOk={() => purchase()}
            okText="Buy"
          >
            <Spin spinning={loading}>
              <div
                style={{
                  width: "250px",
                  margin: "auto",
                }}
              >
                <Badge.Ribbon
                  color="green"
                  text={`${
                    getMarketItem(nftToBuy)?.price / ("1e" + 6)
                  } MATIC`}
                >
                  <img
                    src={nftToBuy?.image}
                    style={{
                      width: "250px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                </Badge.Ribbon>
              </div>
            </Spin>
      </Modal>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default CardMarketplace;
