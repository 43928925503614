import React from 'react';
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div>
      <footer className="footer__1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 space-y-20">
              <div className="footer_logo">
                <Link to="/">
                  <img src={`/img/logos/Realtok-Logo-Negativo-Monocromo.png`} alt="logo" id="logo_js_f" />
                </Link>
              </div>
              {/* <p className="footer__text">
                raroin is a shared liquidity NFT market smart contract
              </p> */}
              <div>
                <ul className="footer__social space-x-10 mb-40">
                  <li>
                    <a href="https://www.instagram.com/realtokdao/?hl=es" rel="noreferrer"  target="_blank">
                      <i className="ri-instagram-line" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/realtokdao" target="_blank" rel="noreferrer" >
                      <i className="ri-twitter-line"  />
                    </a>
                  </li>
                  <li>
                    <a href="hhttps://discord.com/invite/geXmxqkRRr" target="_blank" rel="noreferrer" >
                      <i className="ri-discord-line" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">Realtok</h6>
              <ul className="footer__list">
              <li>
                  <Link to="/proyectos"> Proyectos </Link>
                </li>
                <li>
                  <Link to="/collections"> Colecciones </Link>
                </li>
                <li>
                  <Link to="/profile"> Perfil </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">NFTs</h6>
              <ul className="footer__list">
                <li>
                  <Link to="#"> Creadores </Link>
                </li>
                <li>
                  <Link to="/upload">Crear</Link>
                </li>
                <li>
                  <Link to="/edit-profile"> Editar perfil </Link>
                </li>
                <li>
                  <Link to="/create-collection"> Crear Coleccion </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6">
              <h6 className="footer__title">Company</h6>
              <ul className="footer__list">
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="#">Próximos proyectos</Link>
                </li>
              </ul>
            </div>
          </div>
          <p className="copyright text-center">
            Copyright © 2022.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
