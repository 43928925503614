import React, {useState} from 'react';

import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroCollection from '../../../components/hero/HeroCollection';
import TopArtistCollection from '../../../components/creators/TopArtistCollection';
import SidebarCollection from '../../../components/sidebars/SidebarCollection';

import useDocumentTitle from '../../../components/useDocumentTitle';
import MenuCategoriesCollection from '../elements/MenuCategoriesCollection';
import {Link, useParams} from 'react-router-dom';
import { useCollection } from "../../../hooks/useCollection";


const Collection = () => {
  useDocumentTitle(' Coleccion');
  const { name } = useParams();
  const { Collection } = useCollection(name);
  const [showMore, setShowMore] = useState(false);


  return (
    <div>
      <Header/>
      <HeroCollection Collection={Collection} />
      <div className="row">
        <SidebarCollection Collection={Collection}/>
      </div>
      <TopArtistCollection Collection={Collection}/>
      <div className="d-flex justify-content-center">
        <MenuCategoriesCollection Collection={Collection}/>
      </div>
      <Footer />
    </div>
  );
};

export default Collection;
