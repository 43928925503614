import React from 'react';
import {Link} from 'react-router-dom';
import { useProyectos } from "../../hooks/useProyectos";


function CollectionDefault() {

  const { Proyectos, fetchSuccess } = useProyectos();


  return (
    <div>
      <div className="row mb-30_reset">
        {Proyectos.length === 0 &&
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <img src="img/loader.gif" alt="" />
          </div>
        }
        {Proyectos.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-8" key={i}>
            <div className="collections space-y-10 mb-30">
              <div className="collections_item">
                <Link to={`user/${val.get("ethAddress")}`} className="images-box space-y-10">
                  <img src= {val.get("profilePic")?.url()} alt="prv"/>
                </Link>
              </div>
              <div className="collections_footer justify-content-between">
                <h5 className="collection_title">
                  <Link to={`user/${val.get("ethAddress")}`}>{val.get("username")}</Link>
                </h5>
              </div>
              {/* <div className="creators space-x-10">
                <span className="color_text txt_md">
                  3 items · Created by 
                </span>
                <div className="avatars space-x-5">
                  <Link to="profile">
                    <img
                      src={`img/avatars/avatar_1.png`}
                      alt="Avatar"
                      className="avatar avatar-sm"
                    />
                  </Link>
                </div>
                <Link to="profile">
                  <p className="avatars_name txt_sm"> @Hans... </p> 
                </Link>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CollectionDefault;
