import React from 'react';
import {Link} from 'react-router-dom';
import SearchCollections from './../Menu/SearchCollections';

const Menu = [
  {
    title: 'Mi Perfil',
    link: '/profile',
  },
  {
    title: 'Colecciones',
    link: '/collections',
  },
    {
    title: 'Proyectos',
    link: '/proyectos',
  },
  {
    title: 'Creadores',
    link: '/creators',
  },
  {
    title: 'Crear NFT',
    link: '/upload',
  },
  {
    title: 'Crear Coleccion',
    link: '/create-collection',
  },
  {
    title: 'Mis Colecciones',
    link: '/manage-collection',
  },
  {
    title: 'Editar Perfil',
    link: '/edit-profile',
  },
  {
    title: 'Faq',
    link: '/faq',
  },
];
function MobileMenu() {
  return (
    <div>
      <div className="header__mobile__menu space-y-40">
        <ul className="d-flex space-y-20">
          {Menu.map((val, i) => (
            <li key={i}>
            <Link to={val.link} className="color_black">
              {val.title}
            </Link>
          </li>
          ))}
        </ul>
        <div className="space-y-20">
          {/* <div className="header__search in_mobile w-full">
            <input type="text" placeholder="Search" />
            <button className="header__result">
              <i className="ri-search-line" />
            </button>
          </div> */}
          {/* <SearchCollections/> */}
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
