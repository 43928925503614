import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import { useMoralis } from "react-moralis";
import { useAccount } from "wagmi";
import { useUser } from "../../hooks/useUser";
import { useMoralisDapp } from "../../providers/MoralisDappProvider/MoralisDappProvider";
import { ethers, providers } from "ethers";
import {
  Card,
  Image,
  Tooltip,
  Modal,
  Input,
  Alert,
  Spin,
  Button,
  message,
  AutoComplete,
  Cascader,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Badge,
} from "antd";


const HeroProfile = () => {

  const [loading, setLoading] = useState(false);
  const { walletAddress, chainId, marketAddress, contractABI, minterAddress } = useMoralisDapp();

  const ref = useRef();
  const [isActive, setActive] = useState(false);
  const toggleFollow = () => {
    setActive(!isActive);
  };
  const [isShare, setShare] = useState(false);

  const toggleShare = () => {
    setShare(!isShare);
  };
  const [isMore, setMore] = useState(false);

  const toggleMore = () => {
    setMore(!isMore);
  };

  const { address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const { Moralis } = useMoralis();

  function addUsdc()
  {

    const tokenAddress = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
    const tokenSymbol = "USDC";
    const tokenDecimals = 6;
    const tokenImage =
      "http://polygonscan.com/token/images/centre-usdc_32.png";
    
      const ethers = Moralis.web3Library;
      //const web3 = await Moralis.enableWeb3({ provider: "metamask" });
      
        window.ethereum.request({
         method: "wallet_watchAsset",
         params: {
           type: "ERC20",
           options: {
             address: tokenAddress,
             symbol: tokenSymbol,
             decimals: tokenDecimals,
             image: tokenImage,
           },
         },
       });
    
    console.log("aca");
  }

  async function approveAll() {
    setLoading(true);

    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      minterAddress,
      [
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      signer
    );

    const tx = await contract.setApprovalForAll(marketAddress, true,
      { gasLimit: null, gasPrice: null }
    );

    tx.wait()
      .then((response) => {
        message.success("La aprobacion se ha realizado correctamente");
        //setLoading(false);
      })
      .catch((error) => {
        //setLoading(false);
        message.error(
          "Ocurrio un error al aprobar tu NFT, intenta de nuevo" + error.message
        );
      },
      );
  }



  return (
    <div className="mb-40">
      <div className="hero__profile">
        <div className="cover">
          <img src={user?.get("bannerPic")?.url()} />
        </div>
        <div className="infos">
          <div className="container">
            <div className="row flex-wrap align-items-center justify-content-between sm:space-y-50">
              <div className="col-md-auto mr-20">
                <div className="avatars d-flex space-x-20 align-items-center">
                  <div className="avatar_wrap">
                    <img
                      className="avatar avatar-lg"
                      src= {user?.get("profilePic")?.url()}
                    />
                  </div>
                  <h5>{user?.get("username")}</h5>
                  {(user?.get("isWhitelisted") || user?.get("isUserWhitelisted")) && 
                    <div class="badge"><img src="/img/icons/Badge.svg" alt="icons"/></div>
                  }
                </div>
              </div>
              <div className="col-md-auto">
                <div className="d-sm-flex flex-wrap align-items-center space-x-20 mb-20_reset d-sm-block">
                  <div className="mb-20">
                    <div className="copy">
                      <span className="color_text txt_my">{user?.get("ethAddress")}</span>
                    </div>
                  </div>
                  <div className="mb-20">
                    <Link to="edit-profile" className={`btn btn-dark btn-sm`}>
                      Edit
                    </Link>
                  </div>
                  <div className="mb-20">
                    <button
                    className="btn btn-sm btn-primary btn_auction"
                    onClick={() => addUsdc()}
                    >
                    Add USDC to Wallet
                  </button>
                  </div>

                  <div className="d-flex flex-wrap align-items-center space-x-20">
                    {/* <div className="mb-20">
                      <div
                        className={`btn btn-dark btn-sm ${
                          isActive ? 'btn-prim' : null
                        } `}
                        onClick={toggleFollow}>
                        Follow{isActive ? 'ing' : null}
                      </div>
                    </div> */}
                    <div className="mb-20">
                      <div className="share">
                        <div className="icon" onClick={toggleShare}>
                          <i className="ri-share-line"></i>
                        </div>
                        <div
                          className={`dropdown__popup ${
                            isShare ? 'visible' : null
                          } `}>
                          <ul className="space-y-10">
                            <li>
                            <a href="https://www.facebook.com/" rel="noreferrer"  target="_blank">
                                <i className="ri-facebook-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://www.messenger.com/" rel="noreferrer"  target="_blank">
                                <i className="ri-messenger-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://whatsapp.com" target="_blank" rel="noreferrer" >
                                <i className="ri-whatsapp-line"></i>
                              </a>
                            </li>
                            <li>
                            <a href="https://youtube.com" target="_blank" rel="noreferrer" >
                                <i className="ri-youtube-line"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mb-20">
                      <div className="more">
                        <div className="icon" onClick={toggleMore}>
                          <i className="ri-more-fill"></i>
                        </div>
                        <div
                          className={`dropdown__popup ${
                            isMore ? 'visible' : null
                          } `}>
                          <ul className="space-y-10">
                            <li>
                            <Link
                                    to="#"
                                    className="content space-x-10 d-flex"
                              onClick={() => approveAll()}
                            >
                              Autorizar Marketplace
                            </Link>      
                              <Popup
                                className="custom"
                                ref={ref}
                                trigger={
                                  <Link
                                    to="#"
                                    onClick={() => approveAll()}
                                    className="content space-x-10 d-flex">
                                    <i className="ri-flag-line" />
                                    <span> Report </span>
                                  </Link>
                                }
                                position="bottom center">
                                <div>
                                  <div
                                    className="popup"
                                    id="popup_bid"
                                    tabIndex={-1}
                                    role="dialog"
                                    aria-hidden="true">
                                    <div>
                                      <div className="space-y-20">
                                        <h3>
                                          Thank you, we've received your report
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Popup>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroProfile;
