import {React, useRef, useCallback} from 'react';
//import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Link, useParams } from 'react-router-dom';


import { useNFTsDetails } from "../../hooks/useNFTsDetails";
import { getExplorer } from "../../helpers/networks";
import { useMoralisDapp } from "../../providers/MoralisDappProvider/MoralisDappProvider";
import { useWeb3ExecuteFunction } from "react-moralis";
import {useMoralis, useMoralisQuery, useNewMoralisObject } from "react-moralis";
import { useState } from 'react';
import { Card, Image, Tooltip, Modal, Input, Alert, Spin, Button, Badge } from "antd";
import { toast, ToastContainer } from 'react-toastify';
import { ethers, providers } from 'ethers';
import env from "../../providers/MoralisDappProvider/environment"
import axios from "axios";
import { useHistory } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';


function CardMarketplace({Collection}) {
  const { ENDPOINT,SECRET_KEY,KEY,CLIENT_ID } = env;

  const { address, id } = useParams();
  const history = useHistory()

  const [nftTransfers, setNftTransfers] = useState(null);

  const { NFTsDetails, isLoading, isError, isLastPage, nextPage } = useNFTsDetails(Collection?.get("items"), 20);

  const ref = useRef();
  const closeTooltip = () => ref.current.close();

  const [visible, setVisibility] = useState(false);
  const [nftToBuy, setNftToBuy] = useState(null);
  const [loading, setLoading] = useState(false);

  const contractProcessor = useWeb3ExecuteFunction();
  const { chainId, marketAddress, contractABI, walletAddress,usdcAddress } = useMoralisDapp();
  const contractABIJson = JSON.parse(contractABI);
  const { Moralis } = useMoralis();
  const queryMarketItems = useMoralisQuery("MarketItemCreated", query => query.limit(100000));
  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "objectId",
      "createdAt",
      "price",
      "nftContract",
      "uid",
      "state",
      "tokenId",
      "seller",
      "owner",
      "confirmed",
    ])
  );


  const [paramAddr, setParamAddr] = useState("");
  const [paramId, setParamId] = useState("");


  async function purchase() {
    await approveUSDC()
    setLoading(true);
    const tokenDetails = getMarketItem(nftToBuy);

    const itemID = tokenDetails.uid;
    
    const tokenPrice = tokenDetails.price;
    let royal = [];
    //const res = null;
    
    //axios
    //.get( 
    //  `https://deep-index.moralis.io/api/v2/nft/${nftToBuy.token_address}/${nftToBuy.token_id}/transfers?chain=polygon`,{headers:{"accept": "application/json", "X-API-Key": "No2TdIIDvUICNreO2FrtMluJnIks9tAh2cTdgEzHcTHgIBmR6mmc1H6KQbPxrnct"} }
    //)
    //.then((res) => {
    //  setNftTransfers(res.data.result);
    //  console.log(res.data.result.length);
    //})
    //.catch((err) => console.log(err));

    //console.log(res.data.result.length);
    //console.log(nftToBuy.metadata);

    //if (nftToBuy.metadata && res.data.result.length > 1) {
       royal = nftToBuy.metadata.royalties.map((item) => { return [item.address, item.share] })
    //}

    const provider = new providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const contract = new ethers.Contract(marketAddress, contractABI, signer);


    const transaction = await contract.createMarketSale(
      nftToBuy.token_address,
      itemID,
      tokenPrice,
      royal,
      {
        gasLimit: 1000000
        , gasPrice: null
      });
    transaction.wait()
      .then((resp) => {
        setLoading(false);
        setVisibility(false);
        updateSoldMarketItem();
        toast.success("Purchase Successful");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Purchase Failed");
        console.log(err)
      })



  }

  async function approveUSDC() {
    setLoading(true);
    const tokenDetails = getMarketItem(nftToBuy);
    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(usdcAddress, [
      {
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        stateMutability: "nonpayable",
        type: "function",
      },
    ], signer);

    const tx = await contract.approve(marketAddress, tokenDetails.price,
      { gasLimit: null, gasPrice: null })

    tx.wait()
      .then((response) => {
        toast.success("La aprobacion se ha realizado correctamente");
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        toast.error(
          "Ocurrio un error al aprobar tu USDC, intenta de nuevo" + error.message
        );
      })
  }

  const handleBuyClick = (nft) => {
    setNftToBuy(nft);
    //setVisibility(true);  
    history.push(`/item-details/${nft.token_address}/${nft.token_id}/${nft.project}`)

  };

  function succPurchase() {
    // let secondsToGo = 5;
    // const modal = Modal.success({
    //   title: "Success!",
    //   content: `You have purchased this NFT`,
    // });
    // setTimeout(() => {
    //   modal.destroy();
    // }, secondsToGo * 1000);
    toast.success("You have purchased this NFT");
  }

  function failPurchase() {
    // let secondsToGo = 5;
    // const modal = Modal.error({
    //   title: "Error!",
    //   content: `There was a problem when purchasing this NFT`,
    // });
    // setTimeout(() => {
    //   modal.destroy();
    // }, secondsToGo * 1000);
    toast.error("There was a problem when purchasing this NFT");
  }

  async function updateSoldMarketItem() {
    const id = getMarketItem(nftToBuy).objectId;
    const marketList = Moralis.Object.extend("MarketItemCreated");
    const query = new Moralis.Query(marketList);
    await query.get(id).then((obj) => {
      obj.set("state", "1");
      obj.set("buyer", walletAddress);
      obj.save();
    });
    let url = `${ENDPOINT}/update_nft?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&code=${address}-${id}`

    var config = {
      method: 'PUT',
      body: JSON.stringify({
        sold: true
      })
    }
    fetch(url, config)


  }

  // filter NFTs that are on sale
  const getMarketItem = (nft) => {
    const result = fetchMarketItems?.find(
      (e) =>
        e.nftContract === nft?.token_address &&
        e.tokenId === nft?.token_id &&
        e.state === "0" &&     // State (0, 1, 2) = (For sale, Sold, Cancelled)
        e.confirmed === true
    );
    return result;
  };

  // Infinite scroll:
  // This callback will be called every time the referenced element gets created, with the element itself as an argument.
  // It will be attached to the last card.
  const observerRef = useRef(null)
  const lastCardRef = useCallback((card) => {
    if (isLastPage) return
    if (observerRef.current) observerRef.current.disconnect()
    observerRef.current = new IntersectionObserver(entries => {
        // we are just monitoring one element (the last card)
        if (entries[0].isIntersecting && !isLastPage) nextPage()
    })
    if (card) observerRef.current.observe(card)
  }, [NFTsDetails])

  return (
    <div>
      <div className="row mb-30_reset">
        {NFTsDetails.length === 0 &&
          (!isLoading && !isError ?
            <h2>No hay resultados.</h2>
            :
            Array(4).fill(0).map((_, i) => (
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
              <div className="card__item four">
                <div className="card_body space-y-10">

                  <div className="card_head">
                    <Skeleton height="100%" />
                  </div>

                  <div className="card_bottom">
                    <Skeleton count={2} />
                    <div style={{ marginTop: "1rem" }} className="card_footer d-block space-y-10">
                      <div className="hr" />
                      <div className="d-flex align-items-center space-x-10">
                        <i className="ri-vip-crown-line" />
                        <p className="avatars_name txt_sm">
                          View on explorer
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
          )
        }
        {NFTsDetails.map((nft, i) => (
          <div ref={NFTsDetails.length === i+1 ? lastCardRef : null} className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
            <div className="card__item four">
              <div className="card_body space-y-10">
                {/* =============== */}
                  {/* <div className="creators space-x-10">
                    <div className="avatars space-x-3">
                      <Link to="profile">
                        <img
                          src={`/img/avatars/avatar_1.png`}
                          alt="Avatar"
                          className="avatar avatar-sm"
                        />
                      </Link>
                      <Link to="profile">
                        <p className="avatars_name txt_xs">@</p>
                      </Link>
                    </div>
                  </div> */}
                <div className="card_head">

                <Link to={`/item-details/${nft.token_address}/${nft.token_id}/${nft.project}`}>
                    {nft.animation_url ? (
                      <video
                      //autoPlay
                      controls
                      //poster="nft?.animation_url"
                      //preload="metadata"
                      loop
                      type="video/mp4"
                      className="item_img"
                      src={nft?.animation_url + "#t=0.01"}
                      alt="ImgPreview"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "10px",
                        marginBottom: "15px",
                        position: "relative",
                        top: "-20px"
                      }}
                      />
                    ) : (
                      <img
                        className="item_img"
                        src={nft?.image ? nft?.image : "/img/bg/no-img.png"}
                      />
                    )}
                  </Link>

                  <Link to="#" className="likes space-x-3">
                    <i className="ri-heart-3-fill" />
                    <span className="txt_sm"></span>
                  </Link>
                  {getMarketItem(nft) && (
                  <div className="action">
                    <button className="btn btn-sm btn-primary btn_auction" onClick={() => handleBuyClick(nft)}>
                        <i className="ri-auction-line color_white mr-5px" />
                        {getMarketItem(nft)?.price / 10 ** 6} USDC                        
                    </button>
                  </div>
                  )}
                </div>
                {/* =============== */}
                <div className="card_bottom">
                <h6 className="card_title">
                <Link className="color_black" to={`/item-details/${nft.token_address}/${nft.token_id}/${nft.project}`}>
                  {nft?.metadata?.name ? (
                    nft?.metadata?.name.length > 25 ? (
                      nft.metadata.name.substring(0, 25) + "..."
                    ) : (
                      nft.metadata.name
                    )
                    ) : (
                      nft.symbol
                    )}
                </Link>
                </h6>
                <div className="card_footer d-block space-y-10">
                <div className=" d-flex justify-content-between align-items-center">
                  <div className="creators space-x-10">
                    <Link to="profile">
                      <p className="avatars_name txt_sm">
                        {nft?.token_id?.length > 7 ? (
                        "#" + nft.token_id.substring(0, 7) + "..."
                        ) : (
                        "#" + nft.token_id
                        )}
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="hr" />
                <div className="d-flex align-items-center space-x-10" onClick={() =>
                      window.open(
                        `${getExplorer(chainId)}address/${nft.token_address}`,
                        "_blank"
                      )
                    }>
                  <i className="ri-vip-crown-line" />
                      <p className="avatars_name txt_sm">
                        View on explorer
                      </p>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        { NFTsDetails.length > 0 && isLoading &&
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <img src="/img/loader.gif" alt="" />
          </div>
        }
      </div>
      {/*
      <Modal
            title={`Buy ${nftToBuy?.name} #${nftToBuy?.token_id}`}
            visible={visible}
            onCancel={() => setVisibility(false)}
            onOk={() => purchase()}
            okText="Buy"
          >
            <Spin spinning={loading}>
              <div
                style={{
                  width: "250px",
                  margin: "auto",
                }}
              >
                <Badge.Ribbon
                  color="green"
                  text={`${
                    getMarketItem(nftToBuy)?.price / ("1e" + 6)
                  } USDC`}
                >
                {nftToBuy?.animation_url ? (
                  <video
                    autoPlay
                    controls
                    type="video/mp4"
                    className="item_img"
                    src={nftToBuy?.animation_url}
                    alt="ImgPreview"
                    style={{
                      width: "250px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                ) : (
                  <img
                    className="item_img"
                    src={nftToBuy?.image ? nftToBuy?.image : "/img/bg/no-img.png"}
                    alt="ImagePreview"
                    style={{
                      width: "250px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                )}



                </Badge.Ribbon>
              </div>
            </Spin>
      </Modal> */}

      <Modal
          title={`Buy ${nftToBuy?.metadata?.name} #${nftToBuy?.token_id}`}
          visible={visible}
          onCancel={() => setVisibility(false)}
          footer={[
            <button className="btn btn-primary btn-sm btn-block" onClick={approveUSDC}>Aprobar USDC</button>,
            <button className="btn btn-primary btn-sm btn-block ml-10" onClick={purchase}>Comprar</button>
          ]}
        >
          <Spin spinning={loading}>
            <div
              style={{
                width: "250px",
                margin: "auto",
              }}
            >
              <Badge.Ribbon
                color="green"
                text={`${getMarketItem(nftToBuy)?.price / ("1e" + 6)
                  } USDC`}
              >
                {nftToBuy?.animation_url ? (
                  <video
                    //autoPlay
                    controls
                    type="video/mp4"
                    className="item_img"
                    src={nftToBuy?.animation_url}
                    alt="ImgPreview"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                ) : (
                  <img
                    className="item_img"
                    src={nftToBuy?.image ? nftToBuy.image : "/img/bg/no-img.png"}
                    alt="ImagePreview"
                    style={{
                      width: "250px",
                      borderRadius: "10px",
                      marginBottom: "15px",
                    }}
                  />
                )}

              </Badge.Ribbon>
            </div>
          </Spin>
        </Modal>



      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default CardMarketplace;