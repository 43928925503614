import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { useMoralisCloudFunction } from "react-moralis";

//given a userAddress, return the user's profile

export const useUser = (address) => {
  const [User, setUser] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);

    const { data } = useMoralisCloudFunction(
      "getUser",
      { address: address.toLowerCase() },
    );



  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      setUser(data);
     // console.log(data)
    }
  }, [data]);



  return {
    // getNFTDetails,
    User,
    fetchSuccess,
    // error,
    // isLoading,
  };
};


