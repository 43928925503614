import React from "react";
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import { getEllipsisTxt } from "../../../helpers/formatters";
import Blockie from "./Blockie";
import { Card, Modal } from "antd";
import { useState } from "react";
import Address from "../../Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "../../../helpers/networks";
import { Link } from "react-router-dom";
import { useWeb3Modal } from '@web3modal/react'
import { Web3Button } from '@web3modal/react'
import { useAccount } from 'wagmi'
import { useMoralis } from "react-moralis";

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
    cursor: "pointer",
  },
  text: {
    color: "white",
  },
};

function Account() {
  const { authenticate, isAuthenticated, logout } = useMoralis();

  const { address, isConnecting, isDisconnected } = useAccount()
  const { open } = useWeb3Modal()
  const { chainId } = useMoralisDapp();
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (isDisconnected || isConnecting) {
    return ( 
      <div
        style={styles.account}
        className="bg_brand"
        onClick={() =>
          authenticate({ signingMessage: "Bienvenido a la revolución!" })
        }
      >
    
    <Web3Button />
    </div>        
    )
  }

  return (
    <>
      {/* Show when connected, click to open Modal */}
      <div
        style={styles.account}
        className="bg_brand"
        onClick={() => setIsModalVisible(true)}
      >
        <p style={{ marginRight: "5px", ...styles.text }}>
          {getEllipsisTxt(address, 3)}
        </p>
        <Blockie currentWallet scale={3} />
      </div>

      {/* Modal to view profile and disconnect */}
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        bodyStyle={{
          padding: "15px",
          fontSize: "17px",
          fontWeight: "500",
        }}
        style={{ fontSize: "16px", fontWeight: "500" }}
        width="400px"
      >
        Account
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "1rem",
          }}
          bodyStyle={{ padding: "15px" }}
        >
          <Address
            avatar="left"
            size={6}
            copyable
            style={{ fontSize: "20px" }}
          />
          <div style={{ marginTop: "10px", padding: "0 10px" }}>
            <a
              href={`${getExplorer(chainId)}/address/${address}`}
              target="_blank"
              rel="noreferrer"
            >
              <SelectOutlined style={{ marginRight: "5px" }} />
              View on Explorer
            </a>
          </div>
        </Card>
        <Link to="/profile">
          <div
            className="btn btn-lg btn-primary"
            size="large"
            type="primary"
            style={{
              width: "100%",
              marginTop: "15px",
              borderRadius: "0.5rem",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Ver Perfil
          </div>
        </Link>
        <div
          className="btn btn-lg btn-green"
          size="large"
          type="primary"
          style={{
            width: "100%",
            marginTop: "15px",
          }}
          onClick={() => {
            open();
            setIsModalVisible(false);
          }}
        >
          Administrar Wallet
        </div>
      </Modal>
    </>
  );
}

export default Account;
