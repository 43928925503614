import React, { useRef, useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../../components/useDocumentTitle";
import Header from "../../../components/header/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMoralis, useMoralisFile } from "react-moralis";
import { useWeb3ExecuteFunction } from "react-moralis";
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import {
  Card,
  Image,
  Tooltip,
  Modal,
  Input,
  Alert,
  Spin,
  Button,
  message,
  AutoComplete,
  Cascader,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Badge,
} from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CopyOutlined } from "@ant-design/icons";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useCollectionUser } from "../../../hooks/useCollectionUser";
import { useUsersCollection } from "../../../hooks/useUsersCollection";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import Web3 from "web3";
import Crypto from "crypto-js";
import { ethers, providers } from "ethers";
import { useNavigate } from "react-router-dom"
import { useHistory } from "react-router-dom";
import env from "../../../providers/MoralisDappProvider/environment"
import { useProyectos } from "../../../hooks/useProyectos"
import { useUser } from "../../../hooks/useUser";
import { useAccount } from "wagmi";
import { getUtilities } from "../../../helpers/utilities";

require('dotenv').config()

const Upload = () => {
  const { ENDPOINT, SECRET_KEY, KEY, CLIENT_ID } = env;

  useDocumentTitle(" Crear NFT");

  const { Moralis } = useMoralis();
  const { isConnected: isAuthenticated, address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const [projectName2, setProjectName2] = useState(null);
  const { Collections, fetchSuccess } = useCollectionUser(
    user?.get("ethAddress")
  );
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [file, setFile] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subPrice, setSubPrice] = useState(0);
  const history = useHistory()

  const ItemImage = Moralis.Object.extend("ItemImages");
  const { walletAddress, chainId, marketAddress, contractABI, minterAddress } = useMoralisDapp();

  const [quantity, setQuantity] = useState(1);
  const [Collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState("");
  const [visiblePreview, setVisibilityPreview] = useState(false);
  const [visible, setVisibility] = useState(false);
  const [visibleSell, setVisibilitySell] = useState(false);
  const [visibleSellOnlyMint, setVisibilitySellOnlyMint] = useState(false);
  const [visibleSelling, setVisibilitySelling] = useState(false);
  const [nftToSend, setNftToSend] = useState(null);
  const [advanced, setAdvanced] = useState(false);

  const contractABIJson = JSON.parse(contractABI);
  const { Users } = useUsersCollection(Collection ? [Collection] : []); // get co-creators of this collection
  const { saveFile, moralisFile } = useMoralisFile();
  const contractProcessor = useWeb3ExecuteFunction();
  const [tokenId, setTokenId] = useState();
  const [creators] = useState([]);
  const { Proyectos } = useProyectos();

  const [utilidades, setUtilidades] = useState([])
  const [loadingUtilidades, setLoadingUtilidades] = useState(false)

  function setCreators(addr, price) {
    const result = creators.find(
      ({ address }) => address.toLowerCase() === addr.toLowerCase()
    );
    if (result) {
      result.price = price;
    } else {
      creators.push({ address: addr, price: price });
    }
    const sumPrices = creators.reduce((a, b) => a + b.price, 0);
    setTotalPrice(sumPrices);
  }

  const [project, setProject] = useState(false)

  useEffect(() => {
    function isProject(address, project) {
      const getProject = Proyectos.find(e =>
        e.attributes.accounts[0].toLowerCase() === address.toLowerCase()
      )
      if (getProject !== undefined) {
        setProject(true)
        return true
      }
      return false
    }

    if (Proyectos.length > 0) {
      if (!isProject(walletAddress, project)) {
        let sliceAttrib = [...utilidades]
        sliceAttrib.pop()
        setUtilidades(sliceAttrib)
        return () => { }
      }
    }

    return () => { }
  }, [Proyectos, project])

  // useEffect( () => {

  //   if(Proyectos?.length > 0  && !project){
  //     console.log("llegue no proy")

  //   }
  // }, [project])

  const [sum, setSum] = useState(0);
  const options = [];
  for (let i = 0; i < Collections?.length; i++) {
    options.push({
      value: Collections[i],
      label: (
        <div>
          <img
            src={Collections[i]?.get("image")?.url()}
            className="collections_prefix"
            alt=""
          />{" "}
          {Collections[i]?.get("name")}{" "}
        </div>
      ),
    });
  }

  const { control, register, getValues } = useForm();
  const {
    fields: attributes,
    append: appendAttribute,
    remove: removeAttribute,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "attribute", // unique name for your Field Array
  });
  const {
    fields: royalties,
    append: appendRoy,
    remove: removeRoy,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "royalty", // unique name for your Field Array
  });

  function validateImg(img) {
    // console.log(img.type)
    if (img.size > 114857600) {
      message.error("Image size must be less than 100MB");
    } else {
      setFile(img);
    }
  }

  async function handleCreateClick(soloMint) {
    // ckeck the sum of total royalties shares is less than 20
    let totalRoy = 0;
    for (let roy of getValues("royalty")) {
      totalRoy += parseInt(roy.share);
    }

    if (totalRoy <= 20) {
      if (title && description && file && Collection) {
        var res = false;
        for (let user of Users) {
          // validates that this collection has a project as a co-creator
          if (user?.get("isProject")) {
            res = true;
            const result = creators.find(
              ({ address }) =>
                address.toLowerCase() === user?.get("ethAddress").toLowerCase()
            );
            if (result) {
              result.price = subPrice;
            } else {
              creators.push({
                address: user?.get("ethAddress"),
                price: subPrice,
              });
            }
            console.log(creators);
          }
        }
        if (res) {
          if (soloMint===true){
            setVisibilitySellOnlyMint(true);
          }else{
            setVisibilitySell(true);
          }
        } else {
          message.error(
            "This collection doesn't have a project as a co-creator"
          );
        }
      } else {
        message.error("Please fill out all fields");
      }
    } else {
      message.error("La suma de las royalties debe ser menor a 20%");
    }
    //   console.log("click");

    // @dev: DEBERIA SER  create_nft?
    // const domain = "http://lighthousetest.powerfy.cloud:5000/get_nft"
    // const api_key = "mOAVSBejeqgsl1dTKF2sM6oJrS4xc1uV"
    // const secret_key = "cb5064LRvJkvICip"
    // const client_id = "23yxAT7QDSHr"

    // const url = `${domain}?client_id\=${client_id}&secret_key\=${secret_key}&api_key\=${api_key}&code\=123456`;
    // let response = await fetch(url)

    //   console.log(response.data)

    //   let headers = {
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json',
    //     'Authorization': 'Bearer ' + secret_key
    //   }

    console.log(res);
  }


  function addItemImage() {
    const itemImage = new ItemImage();
    itemImage.set("image", nftToSend.image);
    itemImage.set("nftContract", nftToSend.token_address);
    itemImage.set("tokenId", nftToSend.token_id);
    itemImage.set("name", nftToSend.name);
    itemImage.save();
  }

  function handleConfirm() {
    if (totalPrice > 0) {
      uploadImg(false);
    } else {
      message.error("Price must be greater than 0");
    }
  }

  function handleConfirmSoloMint() {
      uploadImg(true);
  }

  function generateNftID(token) {
    var randomSeed =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    var seed = minterAddress + "-" + token + "-" + randomSeed;
    return Crypto.SHA256(seed).toString();
  }



  async function callOdooAPIcreateNFT(token) {
    var nftID = generateNftID(token);
    let quantity = 0
    utilidades.forEach(attr => quantity += attr.quantity)

    const jsonBody = {
      code: minterAddress + "-" + token,
      name: title,
      description: description,
      quantity: quantity,
      kyc: false,
      nftUID: nftID,
    };
    for (let attr of utilidades) {
      if (attr.quantity > 0) {
        jsonBody[attr.id] = attr.quantity;
        console.log(jsonBody[attr.id])
      }
    }

    const nodesUser = "admin"
    const nodesPass = "66THMQt&ld7KyF!3gUN"
    const credentials = Buffer.from(`${nodesUser}:${nodesPass}`).toString("base64")

    const res = await fetch("https://lighthouseco.work:5001/api/v1.0/nodes/", {
      headers: {
        "Authorization": `Basic ${credentials}`,
      },
    })
    const nodes = await res.json()

    // Load the node that matches the project name
    const node = nodes.find(({ name }) =>
      projectName2.includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName2) ||
      projectName2.replace(/ /g, "").includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName2.replace(/ /g, ""))
    )
    if (!node) {
      throw new Error("No se encontró el proyecto asociado a la colección seleccionada, por favorp pruebe con otra colección.")
    }
    // Load utilities from the node's endpoint
    const endpoint2 = node.dns.slice(0, -1) //+ ":5000/" // add port
    const odoo_server = node.odoo_server
    const odoo_port = node.odoo_port
    const odoo_db = node.odoo_db


    // var url ="https://lighthousetest.powerfy.cloud:5000/create_nft?client_id=23yxAT7QDSHr&secret_key=cb5064LRvJkvICip&api_key=mOAVSBejeqgsl1dTKF2sM6oJrS4xc1uV";
    let url = `${endpoint2}/create_nft?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&odoo_server=${odoo_server}&odoo_port=${odoo_port}&db=${odoo_db}`
    console.log("aca matu");
    console.log(url);

    let config = {
      method: "POST",
      body: JSON.stringify(jsonBody),
    };
    fetch(url, config);

    url = `https://deep-index.moralis.io/api/v2/nft/${minterAddress}/${token}/metadata/resync?chain=polygon&flag=uri&mode=sync`
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("X-API-Key", "No2TdIIDvUICNreO2FrtMluJnIks9tAh2cTdgEzHcTHgIBmR6mmc1H6KQbPxrnct");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
    let response =  fetch(url,requestOptions)
      .then(resp => resp.json())
      .catch(err => console.log(err))
    console.log(response);
  


  }

  function uploadImg(soloMint) {
    // save Image file to IPFS
    setLoading(true);
    saveFile("file", file, {
      type: file.type,
      saveIPFS: true,
      onSuccess: (result) => {
        const imageURI = result.ipfs();
        uploadMetadata(imageURI,soloMint);
      },
      onError: (error) => {
        message.error(error);
        setLoading(false);
      },
    });
  }

  function validAddresses(list) {
    // check valid adress and not repeated
    var array = [];
    for (var i = 0; i < list.length; i++) {
      let addr = list[i].address;
      if (
        Web3.utils.isAddress(addr) &&
        array.map((e) => e.address.toLowerCase()).indexOf(addr.toLowerCase()) === -1
      ) {
        // check if address is not repeated
        array.push(list[i]);
      }
    }
    return array;
  }

  function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
  }

  function uploadMetadata(imageURI,soloMint) {
    // save Metadata to IPFS
    const metadata = {
      name: title,
      description: description,
    };
    if (file.type === "video/mp4") {
      metadata.animation_url = imageURI;
    } else {
      metadata.image = imageURI;
    }

    const attributes = getValues("attribute");

    attributes.push({
      trait_type: "Project",
      value: projectName2
    })

    for (let attr of utilidades) {
      if (attr.quantity > 0) {
        attributes.push({
          trait_type: "Codigo Utilidad",
          value: attr.id
        })
        attributes.push({
          trait_type: "Utilidad",
          value: attr.name
        })
      }
    }

    console.log("aqui");
    console.log(attributes);
    console.log(utilidades);

    const valids = validAddresses(getValues("royalty"));
    const royalties = valids.map((royalty) => {
      return {
        address: royalty.address,
        share: parseInt(royalty.share) * 100, // in basis point
      };
    });

    
    metadata.attributes = attributes;
    metadata.royalties = royalties;

    saveFile(
      "myfile.json",
      { base64: b64EncodeUnicode(JSON.stringify(metadata)) },
      {
        type: "base64",
        saveIPFS: true,
        onSuccess: async (result) => {
          if (soloMint===false){
            setWaitingMessage("Mint: Waiting wallet confirmation (1/2)");
          }else{
            setWaitingMessage("Mint: Waiting wallet confirmation (1/1)");

          }
          const metadataURI = result.ipfs();
          for (let i = 0; i < quantity; i++) {
            await mint(metadataURI,soloMint);
            //if (i === quantity-1) 
            //history.push(`/profile` )
          }
        },
        onError: (error) => {
          message.error(error);
          setWaitingMessage("");
        },
      }
    );
  }

  async function mint(metadataURI,soloMint) {
    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    const contract = new ethers.Contract(
      minterAddress,
      [{
        inputs: [
          {
            internalType: "string",
            name: "tokenURI",
            type: "string"
          }
        ],
        name: "mintToken",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256"
          }
        ],
        stateMutability: "nonpayable",
        type: "function"
      }],
      signer
    );

    const tx = await contract.mintToken(String(metadataURI), {
      gasLimit: null,
      gasPrice: null,
    });

    tx.wait()
      .then(response => {
        try {
          console.log("respose", response);
          if (response.error) throw new Error("");

          console.log(response, "response mint");
          //setLoading(false);
          setWaitingMessage("List: Waiting wallet confirmation (2/2)");
          updateCollection(response);
          message.success("Item created !!!");

          let res = response;
          let tokenId = parseInt(response.events[0].topics[3]);
          return { tokenId, res };
        } catch (error) {
          message.error(error.message + "The NFT was not updated in the database");
          throw error;
        }
      })
      .then(response => {
        try {
          callOdooAPIcreateNFT(response.tokenId);
          console.log("callOdooAPI", response);
          return response.res;
        } catch(error) {
          message.error(error.message + "Odoo API create NFT failed");
          throw error;
        }
      }).then(response => {
        try {
          if (soloMint===false){
            list(response);
            setLoading(false);
            //history.push(`/item-details/${minterAddress}/${parseInt(response.events[0].topics[3])}`)
            console.log("List ", response);
            message.success("Item Listed !!!");
          }else{
            setLoading(false);
            message.success("Item Created !!!");
            setVisibilitySelling(true);
          }
        } catch (error) {
          message.error(error.message + "The NFT was not listed");
          throw error;
        }
      }).catch(error => {
        setLoading(false);
        setWaitingMessage("");
        message.error(error.message + ", Please try again");
        //remove from database
      });
  }

  function updateCollection(response) {
    var items = Collection?.get("items");
    let tokenid = parseInt(response.events[0].topics[3]);
    setTokenId(tokenid);
    items.push((minterAddress + "/" + tokenid + "/" + chainId).toString());
    Collection.set("items", items);
    Collection.save();
  }

  // async function approveAll() {
  //   var tokenAddress = minterAddress;
  //   setLoading(true);
  //   const ops = {
  //     contractAddress: tokenAddress,
  //     functionName: "setApprovalForAll",
  //     abi: [
  //       {
  //         inputs: [
  //           { internalType: "address", name: "operator", type: "address" },
  //           { internalType: "bool", name: "approved", type: "bool" },
  //         ],
  //         name: "setApprovalForAll",
  //         outputs: [],
  //         statMutability: "nonpayable",
  //         type: "function",
  //       },
  //     ],
  //     params: {
  //       operator: marketAddress,
  //       approved: true,
  //     },
  //   };

  //   await contractProcessor.fetch({
  //     params: ops,
  //     onSuccess: () => {
  //       message.success("La aprobacion se ha realizado correctamente");
  //       setLoading(false);
  //     },
  //     onError: (error) => {
  //       setLoading(false);
  //       message.error(
  //         "Ocurrio un error al aprobar tu NFT, intenta de nuevo" + error.message
  //       );
  //     },
  //   });
  // }

  async function approveAll() {
    setLoading(true);

    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      minterAddress,
      [
        {
          inputs: [
            { internalType: "address", name: "operator", type: "address" },
            { internalType: "bool", name: "approved", type: "bool" },
          ],
          name: "setApprovalForAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ],
      signer
    );

    const tx = await contract.setApprovalForAll(marketAddress, true,
      { gasLimit: null, gasPrice: null }
    );

    tx.wait()
      .then((response) => {
        message.success("La aprobacion se ha realizado correctamente");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Ocurrio un error al aprobar tu NFT, intenta de nuevo" + error.message
        );
      },
      );
  }

  async function list(response) {
    var listPrice = totalPrice * 10 ** 6;

    let tokenId = parseInt(response.events[0].topics[3]);
    var tokenAddress = minterAddress;
    var cocreatorsAndShares = [];
    for (let i = 0; i < creators.length; i++) {
      var creator = creators[i].address;
      var share = parseInt(creators[i].price * 10 ** 18);
      var elem = [creator, share.toString()];
      if (creator !== "" && share !== "" && share > 0) {
        cocreatorsAndShares.push(elem);
      }
    }

    const provider = new providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(marketAddress, contractABI, signer);

    const tx = await contract.createMarketItem(
      tokenAddress,
      tokenId,
      cocreatorsAndShares,
      listPrice,
      { gasLimit: null, gasPrice: null }
    );

    tx.wait()
      .then((res) => {
        console.log(res, "response list");
        setVisibilitySell(false);
        console.log("wait tokenId undef", tokenId);

        message.success("Tu NFT ha sido listado en el marketplace");
        setVisibilitySelling(true);

        console.log("response listing ", res);
      })
      .catch((error) => {
        setLoading(false);
        message.error("Ocurrio un error al listar tu NFT, intenta de nuevo");
        console.log("error listing ", error);
        setVisibilitySell(false);
      });
  }

  const handleQuantity = (event, index, item) => {
    var actualPrice = 0;
    var newAtt = utilidades.map((attribute, i) => {
      if (i === index) {
        attribute.calculatedPrice = item.price * event.target.value;
        attribute.quantity = Number(event.target.value);
      }
      actualPrice = actualPrice + attribute.calculatedPrice;
      return attribute;
    });
    setSubPrice(actualPrice);
    setTotalPrice(actualPrice);
    setUtilidades(newAtt);
  };

  async function verNFT() {
    history.push(`/item-details/${minterAddress}/${tokenId}`)
  }

  function createPoster($video) {
    //here you can set anytime you want
    $video.currentTime = 5;
    var canvas = document.createElement("canvas");
    canvas.width = 350;
    canvas.height = 200;
    canvas.getContext("2d").drawImage($video, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL("image/jpeg");;
}

  function Preview() {
    return (
      <div className="mt-10">
        <div className="card__item three">
          <div className="card_body space-y-10">
            <div className="card_head">
              <Link to="#">
                {file?.type === "video/mp4" ? (
                  <video
                    //autoPlay
                    controls
                    //loop
                    type="video/mp4"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10px",
                      marginBottom: "15px",
                      position: "relative",
                      top: "-20px"
                    }}
                  className="item_img"
                  src={file ? URL.createObjectURL(file)  + "#t=1": null}
                    //src={nft?.animation_url + "#t=0.01"}
                    />
                ) : (
                  <img
                    src={file ? URL.createObjectURL(file) : null}
                    className="item_img"
                    alt=""
                  />
                )}
              </Link>
              <Link to="#" className="likes space-x-3">
                <i className="ri-heart-3-fill" />
              </Link>
            </div>
            {/* =============== */}
            <div className="card_bottom">
              <h6 className="card_title">
                <Link className="color_black" to="#">
                  {title ? title : "Name"}
                </Link>
              </h6>
              <div className="card_footer d-block space-y-10">
                <div
                  className="
                    d-flex
                    justify-content-between
                    align-items-center"
                >
                  <div className="creators space-x-10">
                    <Link to="profile">
                      <p className="avatars_name txt_sm">#1</p>
                    </Link>
                  </div>
                </div>
                <div className="hr" />
                <div className="d-flex align-items-center space-x-10">
                  <i className="ri-vip-crown-line" />
                  <p className="avatars_name txt_sm">View on explorer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <div>
        <Header />
        <div className="hero__upload">
          <div className="container">
            <div className="space-y-20">
              {/* <Link to="upload-type" className="btn btn-white btn-sm switch">
                Cambiar a Multiple
              </Link> */}
              <h1 className="title">Crea un coleccionable único</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="box in__upload mb-120">
            <div className="row ml-20 mt-20 space-x-80">
              <div className="col-lg-7 space-y-50">
                <div className="row left__part space-y-40 upload_file">
                  {!file ? (
                    <Fragment>
                      <div className="space-y-20">
                        <img
                          className="icon"
                          src={`img/icons/upload.svg`}
                          alt="upload"
                        />
                        <h5>Imagen de NFT</h5>
                        <p className="color_text">
                          JPG, PNG, GIF, WEBP, MP4 or MP3. Max 100mb.
                        </p>
                        <ToastContainer />
                      </div>
                      <div className="space-y-20">
                        <div id="boxUpload">
                          <Link to="#" className="btn btn-white">
                            Browse files
                          </Link>
                          <input
                            type="file"
                            onChange={(e) => validateImg(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {file?.type == "video/mp4" ? (
                        <video autoPlay controls className="item_img ">
                          <source
                            src={URL.createObjectURL(file)}
                            type={file.type}
                          />
                          Your browser does not support HTML5 video.
                        </video>
                      ) : (
                        <img
                          src={URL.createObjectURL(file)}
                          className="item_img"
                          alt=""
                        />
                      )}
                      <div className="cancell">
                        <button
                          type="button"
                          className="btn btn-white btn-sm ri-close-line"
                          onClick={() => setFile(null)}
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="row mt-30">
                  <div className="space-y-30">
                    <div className="space-y-10">
                      <span className="nameInput">Nombre</span>
                      <input
                        type="text"
                        placeholder="e. g. `realtok design art`"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="space-y-10">
                      <span className="nameInput">Descripción</span>
                      <textarea
                        style={{ minHeight: 110 }}
                        placeholder="e. g. `realtok design art`"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="col-md-auto mb-10">
                      <input
                        placeholder="Cantidad de NFTs"
                        min="1"
                        type="number"
                        onChange={(e) => {
                          console.log(e)
                          setQuantity(e.target.value)
                        }}
                      ></input>
                    </div>
                    <div className="space-y-10">
                      <span className="variationInput">Elige la colección</span>
                      <Select
                        className="color_white"
                        options={options}
                        onChange={async (e) => {
                            const _collection = e.value
                            setCollection(_collection)
                            const projectAddress = _collection.get("co_creators")[0].address
                            const _project = Proyectos.find((item) => projectAddress.toLowerCase() === item.get("ethAddress"))
                            const projectName = _project.get("username").toLowerCase()
                            setProjectName2(projectName);

                            try {
                              setLoadingUtilidades(true)
                              
                              const utilities = await getUtilities(projectName)
                              
                              setUtilidades(utilities.flatMap(util => util.is_membership ? [({
                                quantity: 0,
                                calculatedPrice: 0,
                                name: util.name,
                                description: util.description,
                                price: util.price_unit,
                                id: +util.code,
                              })] : []))
                            } catch(err) {
                              alert("Hubo un error al cargar las utilidades del proyecto asociado a la colección seleccionada, por favor intente seleccionarla nuevamente.")
                            } finally {
                              setLoadingUtilidades(false)
                            }
                          }}
                      />
                    </div>
                    <div className="space-y-10">
                      <span className="nameInput">
                        Regalias: 5%, 10%, 20%. El máximo es 20%
                      </span>
                      <div className="space-y-10">
                        {royalties.map((item, index) => (
                          <Row gutter={8} key={item.id}>
                            <Col span={12}>
                              <input
                                type="text"
                                placeholder="0x4b....81Ca2"
                                {...register(`royalty.${index}.address`)}
                              />
                            </Col>
                            <Col span={9}>
                              <Controller
                                render={({ field }) => (
                                  <input
                                    type="number"
                                    placeholder="0%"
                                    max={20}
                                    min={0}
                                    {...field}
                                  />
                                )}
                                name={`royalty.${index}.share`}
                                control={control}
                              />
                            </Col>
                            <Col span={3}>
                              <button
                                type="button"
                                className="btn btn-white btn-lg ri-close-line"
                                onClick={() => removeRoy(index)}
                              />
                            </Col>
                          </Row>
                        ))}
                        <button
                          type="button"
                          className="btn btn-dark btn-md mt-20"
                          onClick={() => appendRoy({ address: "", share: "" })}
                        >
                          + Royalty
                        </button>
                      </div>
                    </div>
                    {/* <div className="space-y-10">
                    <span className="variationInput">Regalias</span>
                    <input type="number" max="50"  placeholder="e. g. 10%" />
                    <p>Sugerido: 0%, 10%, 20%, 30%. El máximo es 50%. (2,5% fee)</p>
                  </div> */}
                  </div>
                </div>
                <div className="row mt-30">
                  <button
                    className="btn btn-primary btn-lg mb-30"
                    onClick={() => setAdvanced(!advanced)}
                    style={{ width: "100%", borderRadius: "10px" }}
                  >
                    {advanced
                      ? "Ocultar configuracion avanzada"
                      : "Mostrar configuracion avanzada"}
                  </button>
                  {advanced && (
                    <Fragment>
                      <div className="col-lg-6">
                        <div className="space-y-10">
                          {attributes.map((item, index) => (
                            <Row gutter={8} key={index}>
                              <Col span={12}>
                                <input
                                  type="text"
                                  {...register(`attribute.${index}.trait_type`)}
                                />
                              </Col>
                              <Col span={9}>
                                <Controller
                                  render={({ field }) => (
                                    <input type="text" {...field} />
                                  )}
                                  name={`attribute.${index}.value`}
                                  control={control}
                                />
                              </Col>
                              <Col span={3}>
                                <button
                                  type="button"
                                  className="btn btn-white btn-lg ri-close-line"
                                  onClick={() => removeAttribute(index)}
                                />
                              </Col>
                            </Row>
                          ))}
                          <button
                            type="button"
                            className="btn btn-dark btn-md mt-20 mb-10"
                            onClick={() =>
                              appendAttribute({
                                trait_type: "Size",
                                value: "M",
                              })
                            }
                          >
                            + Atributo
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="col-lg-4 fix-margin">
                <h4>Utilidades</h4>
                { utilidades.length > 0 ? (
                  <div className="row space-y-20 scrollbar mt-10">
                    {utilidades.map((item, index) => (
                      <Fragment>
                        <div
                          className={`${item.quantity > 0 ? "toggleSelection" : ""
                            }  choose_box_access  text-left`}
                        >
                          <div className="space-y-5">
                            <div className="row">
                              <div className="col-lg-1">
                                <i class="ri-building-line"></i>
                              </div>
                              <div className="col-lg-8">
                                <h6>{item.name}</h6>
                              </div>
                            </div>
                            <div className="row">
                              <p className="color_grey txt_sm">
                                {item.description}
                              </p>
                            </div>
                            <div className="row">
                              <div className="col-lg-3">
                                <input
                                  type="number"
                                  max="50"
                                  min="0"
                                  placeholder="cant."
                                  value={item.quantity}
                                  onChange={(e) => {
                                    handleQuantity(e, index, item);
                                  }}
                                />
                              </div>
                              <div className="col-lg-3">
                                <p>Precio: </p>
                                {/* <span className='color_brand'>{ price[index]? price[index]: 0 } USD</span> */}
                                <span className="color_brand">{`${item.calculatedPrice} USDC`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))
                    }
                  </div>
                ) : ( loadingUtilidades ? (
                  <p style={{ marginTop: "1rem" }}>Cargando utilidades...</p>
                  ) : (
                  <p style={{ marginTop: "1rem" }}>Seleccione una colección para cargar las utilidades...</p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="fixed_row bottom-0 left-0 right-0">
          <div className="container">
            <div className="row content justify-content-between mb-20_reset">
              <div className="col-md-auto col-12 mb-20">
                <div className="space-x-10">
                  <Link to="/upload-type" className="btn btn-light others_btn">
                    Cancel
                  </Link>
                    {/*}
                  <Link
                    to="#"
                    className="btn btn-dark others_btn"
                    onClick={() => setVisibilityPreview(true)}
                  >
                    Preview
                  </Link>
                  {*/}
                </div>
              </div>
              <div className="col-md-auto col-12 mb-20">
                <div className="">
                  {/*}
                  <button
                    className="btn btn-primary btn_create m-1"
                    onClick={() => approveAll()}
                  >
                    Aprobar
                  </button>
                  {*/}
                  <button
                    className="btn btn-primary btn_create m-1"
                    onClick={() => handleCreateClick(false)}
                  >
                    Crear y Listar
                  </button>
                  <button
                    className="btn btn-primary btn_create m-1"
                    onClick={() => handleCreateClick(true)}
                  >
                    Solo Crear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          visible={visiblePreview}
          onCancel={() => setVisibilityPreview(false)}
          footer={null}
          width={450}
        >
          <h4>Preview</h4>
          <Preview />
        </Modal>
        <Modal
          visible={visible}
          onCancel={() => setVisibility(false)}
          // onOk={() => list("nftToSend", "price")}
          okText="Create"
          footer={null}
        >
          <h4>Upload</h4>
          <div className="mt-10">
            <Spin spinning={loading}>
              <Preview />
            </Spin>
            <Button
              onClick={() => uploadImg()}
              type="primary"
              style={{
                height: "40px",
                width: "100%",
                borderRadius: "15px",
              }}
            >
              Crear
            </Button>
          </div>
        </Modal>

        <Modal
          title={`Listar  "${title}" en Market`}
          visible={visibleSell}
          onCancel={() => setVisibilitySell(false)}
          // onOk={() => list(nftToSend, price)}
          okText="Ok"
          footer={null}
        >
          <div className="mt-10">
            <Spin spinning={loading} tip={waitingMessage}>
              {/* tip={ !waitingConf ? "Subiendo Metadata a IPFS..." : "Esperando confirmacion de Wallet"  } spinning={loading}> */}
              <div
                style={{
                  width: "250px",
                  margin: "auto",
                }}
              ></div>
              <div className="space-y-10">
                {Users?.map((val, index) => (
                  <div className="row">
                    <div className="col-xl-6 col-sm-5">
                      <div className="creator_item creator_card space-x-10 ">
                        <div className="avatars space-x-10">
                          <Link
                            to={`/user/${val?.get("ethAddress")}`}
                            target="_blank"
                          >
                            <img
                              src={
                                val?.get("profilePic")
                                  ? val?.get("profilePic").url()
                                  : `/img/avatars/avatar_1.png`
                              }
                              alt="Avatar"
                              className="avatar avatar-sm"
                            />
                          </Link>
                          <Link
                            to={`/user/${val?.get("ethAddress")}`}
                            target="_blank"
                          >
                            <p className="avatars_name color_black">
                              {val?.get("username").length > 17
                                ? val?.get("username").substr(0, 17) + "..."
                                : val?.get("username")}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-5">
                      {val?.get("isProject") ? (
                        <Input
                          className="width_4"
                          style={{ color: "white" }}
                          type="number"
                          autoFocus
                          placeholder="Project price"
                          value={subPrice}
                          disabled={!project}
                          //onChange={handleChange}
                          onChange={(e) => {
                            setCreators(
                              val?.get("ethAddress"),
                              Number(e.target.value)
                            );
                            setSubPrice(e.target.value);

                          }}

                        />
                      ) : (
                        <Input
                          className="width_4"
                          type="number"
                          autoFocus
                          min={0}
                          placeholder="price"
                          onChange={(e) => {
                            setCreators(
                              val?.get("ethAddress"),
                              Number(e.target.value)
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-20">
                {/* <p className="txt_lg">Subtotal: {totalPrice * 0.975} usdc</p> */}
                <p className="txt_lg">Total: {totalPrice} usdc</p>
                <p className="txt_md">2.5% is distributed to DAO</p>
              </div>
              <Button
                onClick={() => handleConfirm()}
                type="primary"
                className="mt-20 btn_lg btn_primary"
                disabled={loading}

                style={{
                  height: "40px",
                  width: "100%",
                  borderRadius: "15px",
                }}
              >
                Confirmar
              </Button>
            </Spin>
          </div>
        </Modal>


        <Modal
          title={`Solo Mintear  "${title}"`}
          visible={visibleSellOnlyMint}
          onCancel={() => setVisibilitySellOnlyMint(false)}
          // onOk={() => list(nftToSend, price)}
          okText="Ok"
          footer={null}
        >
          <div className="mt-10">
            <Spin spinning={loading} tip={waitingMessage}>
              {/* tip={ !waitingConf ? "Subiendo Metadata a IPFS..." : "Esperando confirmacion de Wallet"  } spinning={loading}> */}
              <div
                style={{
                  width: "250px",
                  margin: "auto",
                }}
              ></div>
              <Button
                onClick={() => handleConfirmSoloMint()}
                type="primary"
                className="mt-20 btn_lg btn_primary"
                style={{
                  height: "40px",
                  width: "100%",
                  borderRadius: "15px",
                }}
              >
                Confirmar
              </Button>
            </Spin>
          </div>
        </Modal>


        <Modal
          title={`"${title}" Listado correctamente en el Market`}
          visible={visibleSelling}
          onCancel={() => setVisibilitySelling(false)}
          // onOk={() => list(nftToSend, price)}
          okText="Ok"
          footer={[
            /*<button className="btn btn-primary btn-sm btn-block" onClick={approveUSDC}>Aprobar USDC</button>*/
            <button className="btn btn-primary btn-sm btn-block ml-10" onClick={verNFT}>Ver NFT</button>
          ]}
        >
          <div className="mt-10">
          <Spin spinning={loading}>
            <div
              style={{
                width: "250px",
                margin: "auto",
              }}
            >
            </div>
          </Spin>
          </div>
        </Modal>




      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default Upload;