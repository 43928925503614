import React, {useRef, useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import useDocumentTitle from '../../../components/useDocumentTitle';
import Header from '../../../components/header/Header';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMoralis, useMoralisFile } from "react-moralis";
import { useWeb3ExecuteFunction } from "react-moralis";
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import { Card, Image, Tooltip, Modal, Input, Alert, Spin, Button, message,  AutoComplete,
   Cascader, Col, DatePicker, InputNumber, Row, Select, } from "antd";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CopyOutlined } from '@ant-design/icons';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useNewMoralisObject } from "react-moralis";
import { Redirect } from 'react-router-dom';
import { useCollection } from "../../../hooks/useCollection";
import {useParams} from 'react-router-dom';
import CardEditCollection from './CardEditCollection';
import { useAccount } from 'wagmi';
import { useUser } from "../../../hooks/useUser";


const EditCollection = () => {
  useDocumentTitle(' Editar Coleccion');

  const { name } = useParams(); 
  const { Collection } = useCollection(name); // get collection by name

  const { isConnected: isAuthenticated, address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const [fileLogo, setFileLogo] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  const [fileBanner, setFileBanner] = useState(null);
  const [cancelledColImage, setCancelledColImage] = useState(false);
  const [cancelledColLogo, setCancelledColLogo] = useState(false);
  const [cancelledColBanner, setCancelledColBanner] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const { saveFile, moralisFile } = useMoralisFile();
  const [facebook, setFacebook] = useState('defaultfacebook');
  const [instagram, setInstagram] = useState('defaultinstagram');
  const [twitter, setTwitter] = useState('defaulttwitter');
  const [discord, setDiscord] = useState('defaultdiscord');
  const [visibleCreating, setVisibleCreating] = useState(false);
  const { control, register, getValues  } = useForm();
  const { fields: cocreators, append: appendCocreator, remove: removeCocreator } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "cocreators", // unique name for your Field Array
  });
  const { save } = useNewMoralisObject("Collection");


  async function handleCreateClick () {

      if (Collection.get("socialLinks")){
        var socialLinks = Collection.get("socialLinks");
      }else{
        var socialLinks = {
          facebook: '',
          instagram: '',
          twitter: '',
          discord: '',
        }
      }

      if (title != null) {
        Collection.set("name", title);
      }  
      if (description != null) {
        Collection.set("description", description);
      }  

      if (facebook != 'defaultfacebook') {socialLinks.facebook = facebook;};
      if (instagram != 'defaultinstagram') {socialLinks.instagram = instagram;};
      if (twitter != 'defaulttwitter') {socialLinks.twitter = twitter;};
      if (discord != 'defaultdiscord') {socialLinks.discord = discord;};
      Collection.set("socialLinks", socialLinks);

      if (fileLogo != null) {
       await saveFile("photo.jpg", fileLogo, {
          type: "image/png",
          onSuccess: (result) => {
          Collection.set("logo", result);
          },
          onError: (error) => console.log(error)
        });
      }  
      if (fileImage != null) {
        await  saveFile("photo.jpg", fileImage, {
          type: "image/png",
          onSuccess: (result) => {
          Collection.set("image", result);
          },
          onError: (error) => console.log(error)
        });
      }  
      if (fileBanner != null) {
       await saveFile("photo.jpg", fileBanner, {
          type: "image/png",
          onSuccess: (result) => {
          Collection.set("banner", result);
          },
          onError: (error) => console.log(error)
        });
      }

        setVisibleCreating(true);
        Collection.save().then(
          () => {
            toast("Collection updated");
            setVisibleCreating(false);
          },
          (error) => {
            toast.error(error.message);
            setVisibleCreating(false);
          }
        );
  
  };



  if (isAuthenticated){
    return (
      <div>
        <Header />
        { Collection?.get("creatorAddress").toLowerCase() == user?.get("ethAddress").toLowerCase() ?
          (
            <div>
              <div className="hero__upload">
                <div className="container">
                  <div className="space-y-20">
                    <h1 className="title">Editar coleccion</h1>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="box in__upload mb-60">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 space-y-30">
                      <div>
                      <h4 className="title">Imagen de perfil</h4>
                      <p className="txt_sm mt-10">Esta imagen se mostrara en la pagina de la coleccion. Se recomienda 300 x 300</p>
                      <div className="col-lg-2 mt-20 space-y-10">
                        <div className="row collection_logo__part space-y-40 upload_file" >
                        {!fileLogo ? (
                                    <Fragment>
                                      {Collection?.get("logo") && !cancelledColLogo ? (
                                          <Fragment>
                                            <img src = {Collection?.get("logo")?.url()} className="item_img"/> 
                                            <div className="cancell">
                                              <button type="button" className="btn btn-white btn-sm ri-close-line" onClick={() => setCancelledColLogo(true)}/>
                                            </div>
                                          </Fragment>
                                          ) : (
                                            <Fragment>
                                                 <div id="boxUpload">
                                                <div className="space-y-20">
                                                    <img
                                                    className="icon"
                                                    src={`/img/icons/upload.svg`}
                                                    alt="upload"
                                                    />
                                                    <input type="file" onChange={(e) => setFileLogo(e.target.files[0])}/>
                                                </div>
                                              </div>
                                              </Fragment>
                                              )}
                                      <ToastContainer position="bottom-right" />
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {fileLogo?.type == "video/mp4" ? (
                                            <video autoPlay  controls  className="item_img ">
                                            <source
                                            src={URL.createObjectURL(fileLogo)}
                                            type={fileLogo.type}
                                            />
                                            Your browser does not support HTML5 video.
                                            </video>
                                        ) : (
                                            <img src = {URL.createObjectURL(fileLogo)} className="item_img"/> 
                                        )}
                                        <div className="cancell">
                                          <button type="button" className="btn btn-white btn-sm ri-close-line" onClick={() => setFileLogo(null)}/>
                                        </div>
                                    </Fragment>
                                )}
                        </div>
                      </div>
                      </div>
                      <div>
                      <h4 className="title">Imagen principal</h4>
                      <p className="txt_sm mt-10">
                        Esta imagen se utilizará para presentar su colección en la página de inicio, 
                         las páginas de categoría u otras áreas promocionales. Se recomienda 800 x 600</p>
                      <div className="col-lg-5 mt-20">
                        <div className="row collection_left__part space-y-40 upload_file" >
                        {!fileImage ? (
                                    <Fragment>
                                      {Collection?.get("image") && !cancelledColImage ? (
                                          <Fragment>
                                            <img src = {Collection?.get("image")?.url()} className="item_img"/> 
                                            <div className="cancell">
                                              <button type="button" className="btn btn-white btn-sm ri-close-line" onClick={() => setCancelledColImage(true)}/>
                                            </div>
                                          </Fragment>
                                          ) : (
                                            <Fragment>
                                                 <div id="boxUpload">
                                                <div className="space-y-20">
                                                    <img
                                                    className="icon"
                                                    src={`/img/icons/upload.svg`}
                                                    alt="upload"
                                                    />
                                                    <input type="file" onChange={(e) => setFileImage(e.target.files[0])}/>
                                                </div>
                                              </div>
                                              </Fragment>
                                              )}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {fileImage?.type == "video/mp4" ? (
                                            <video autoPlay  controls  className="item_img ">
                                            <source
                                            src={URL.createObjectURL(fileImage)}
                                            type={fileImage.type}
                                            />
                                            Your browser does not support HTML5 video.
                                            </video>
                                        ) : (
                                            <img src = {URL.createObjectURL(fileImage)} className="item_img"/> 
                                        )}
                                        <div className="cancell">
                                          <button type="button" className="btn btn-white btn-sm ri-close-line" onClick={() => setFileImage(null)}/>
                                        </div>
                                    </Fragment>
                                )}
                        </div>
                      </div>
                      </div>
                      <div>
                      <h4 className="title">Imagen Banner</h4>
                      <p className="txt_sm mt-10">Esta imagen aparecerá en la parte superior de la página de tu colección. 
                      Evite incluir demasiado texto en esta imagen de banner, ya que las dimensiones cambian en diferentes dispositivos. Se recomienda 1400 x 350</p>
                      <div className="row mt-30 collection_left__part space-y-40 upload_file" >
                      {!fileBanner ? (
                                    <Fragment>
                                      {Collection?.get("banner") && !cancelledColBanner ? (
                                          <Fragment>
                                            <img src = {Collection?.get("banner")?.url()} className="item_img"/> 
                                            <div className="cancell">
                                              <button type="button" className="btn btn-white btn-sm ri-close-line" onClick={() => setCancelledColBanner(true)}/>
                                            </div>
                                          </Fragment>
                                          ) : (
                                            <Fragment>
                                                 <div id="boxUpload">
                                                <div className="space-y-20">
                                                    <img
                                                    className="icon"
                                                    src={`/img/icons/upload.svg`}
                                                    alt="upload"
                                                    />
                                                    <input type="file" onChange={(e) => setFileBanner(e.target.files[0])}/>
                                                </div>
                                              </div>
                                              </Fragment>
                                              )}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {fileBanner?.type == "video/mp4" ? (
                                            <video autoPlay  controls  className="item_img ">
                                            <source
                                            src={URL.createObjectURL(fileBanner)}
                                            type={fileBanner.type}
                                            />
                                            Your browser does not support HTML5 video.
                                            </video>
                                        ) : (
                                            <img src = {URL.createObjectURL(fileBanner)} className="item_img"/> 
                                        )}
                                        <div className="cancell">
                                          <button type="button" className="btn btn-white btn-sm ri-close-line" onClick={() => setFileBanner(null)}/>
                                        </div>
                                    </Fragment>
                                )}
                      </div>
                      </div>
                      <div className="row mt-30">
                        <div className="space-y-20">
                          <div className="space-y-10">
                            <span className="nameInput">Nombre</span>
                            <input
                              type="text"
                              defaultValue={Collection?.get("name")}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                          <div className="space-y-10">
                            <span className="nameInput">Descripción</span>
                            <textarea
                              style={{minHeight: 110}}
                              defaultValue={Collection?.get("description")}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </div>
                          <h3 className="mb-20">Social Links</h3>
                        <div className="form-group space-y-15">
                            <div className="d-flex flex-column">
                              <span className="nameInput mb-10">Instagram</span>
                              <input
                                type="text"
                                placeholder={"instagram username"}
                                defaultValue={Collection?.get("socialLinks")?.instagram ? Collection.get("socialLinks")?.instagram : ""}
                                onChange={(e) => setInstagram(e.target.value)}
                              />
                            </div>
                            <div className="d-flex flex-column">
                              <span className="nameInput mb-10">Twitter</span>
                              <input
                                type="text"
                                placeholder={"twitter username"}
                                defaultValue={Collection?.get("socialLinks")?.twitter ? Collection.get("socialLinks")?.twitter : ""}
                                onChange={(e) => setTwitter(e.target.value)}
                              />
                            </div>
                            <div className="d-flex flex-column">
                              <span className="nameInput mb-10">Facebook</span>
                              <input
                                type="text"
                                placeholder={"facebook username"}
                                defaultValue={Collection?.get("socialLinks")?.facebook ? Collection.get("socialLinks")?.facebook : ""}
                                onChange={(e) => setFacebook(e.target.value)}
                              />
                            </div>
                            <div className="d-flex flex-column">
                              <span className="nameInput mb-10">Discord</span>
                              <input
                                type="text"
                                placeholder={"discord username or invitation code"}
                                defaultValue={Collection?.get("socialLinks")?.discord ? Collection.get("socialLinks")?.discord : ""}
                                onChange={(e) => setDiscord(e.target.value)}
                              />
                            </div>
                        </div>
                        </div>
                      </div>
                      {/* <div className="row mt-30">
                        <span className="mb-10">Co-Creadores</span>
                        <p className='mb-20'>
                            Cada venta dentro de esta coleccion se dividira correspondientemente
                            entre los co-creadores que participan en su creacion colectiva.
                            Los colaboradores pueden recibir pagos por los artículos que han creado 
                            y crear nuevos artículos dentro de la coleccion.
                        </p>
                        <div className="space-y-10">
                            {cocreators.map((item, index) => (
                            <Row gutter={8} key={item.id}>
                                <Col span={17}>
                                <input type="text" placeholder="Address, por ejemplo: 0x4b...81Ca2" {...register(`cocreators.${index}.address`)} />
                                </Col>
                                <Col span={5}>
                                <Controller 
                                render={({ field }) => 
                                <input type="number" max="10" min="0"  placeholder="e. g. 5%" {...field} />}
                                name={`cocreators.${index}.value`}
                                control={control}
                                />
                                </Col>
                                <Col span={2}>
                                <button type="button" className="btn btn-white btn-lg ri-close-line" 
                                onClick={() => removeCocreator(index)} />
                                </Col>
                            </Row>
                            ))}   
                            <button type="button" className="btn btn-dark btn-md mt-20 mb-20" onClick={() => appendCocreator({ address: "", value: "" })}>
                            Agregar Co-Creador
                            </button>        
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="box in__upload mb-120">
                    <CardEditCollection Collection={Collection}/>
                </div>
              </div>
              <div className="fixed_row bottom-0 left-0 right-0">
                <div className="container">
                  <div className="row content justify-content-between mb-20_reset">
                    <div className="col-md-auto col-12 mb-20">
                      <div className="space-x-10">
                        <Link
                          to="/"
                          className="btn btn-dark others_btn">
                          Cancel
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-auto col-12 mb-20">
                      <button
                        className="btn btn-primary btn_create" onClick={() => handleCreateClick()}>
                        Guardar
                      </button>
                    </div>
                  </div>
                  <Modal
                    visible={visibleCreating}
                    onCancel={() => setVisibleCreating(false)}
                    footer={null}
                    width={450}
                  >
                    <h4>Creating Collection</h4>
                    <Spin tip={("Creating collection, wait")} spinning={true}>
                    </Spin>
                  </Modal>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="hero__upload">
                <div className="container">
                  <div className="space-y-20">
                    <h1 className="title">Gestionar mis NFTs</h1>
                  </div>
                </div>
              </div>
            </div>
          )
          }
      </div>
    );
  }else{
    return <Redirect to="/" />
  }
  //   bybg5208938.1

};

export default EditCollection;




































