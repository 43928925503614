import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link} from 'react-router-dom';
import { useUsersHome } from "../../hooks/useUsersHome";

export default function TopArtist() {

  const { Users } = useUsersHome();
  

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1200,
    slidesToShow: Math.min(4,Users.length),
    slidesToScroll: 1,
    pauseOnHover: false,
    margin: 20,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: Math.min(3,Users.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2,Users.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1,Users.length),
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div className="section__artists mt-50">
      <div className="container">
        <div className="space-y-30">
          <div className="d-flex justify-content-between align-items-center">
              <h5 className="section__title mt-10">Creadores</h5>
              <Link to="/creators" className="btn btn-dark btn-sm">
                Ver todo
              </Link>
            </div>
          <div className="section_body swiper_artists">
            <Slider {...settings}>
              {Users.map((val, i) => (
                <div className="item" key={i}>
                  <div className="creator_item creator_card rounded_border space-x-10">
                    <div className="avatars space-x-10">
                      <div className="media">
                        {val?.get("isProject") && (
                           <div className="badge">
                           <img src={`/img/icons/Badge.svg`} alt="icons" />
                         </div>
                          )}
                        <Link to={`/user/${val.get("ethAddress")}`}>
                          <img
                            src={val?.get("profilePic") ? val.get("profilePic").url() : `/img/avatars/avatar_1.png`}
                            alt="Avatar"
                            className="avatar avatar-md"
                          />
                        </Link>
                      </div>
                      <div>
                        <Link to={`/user/${val.get("ethAddress")}`}>
                          <p className="avatars_name color_black">
                            {val.get("username")}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
