import React, { useRef, useCallback } from 'react';
import {Link} from 'react-router-dom';
import { useCollectionUser } from "../../hooks/useCollectionUser";
import { useUsersCollection } from "../../hooks/useUsersCollection";

function CollectionUser({address}) {
  // Cannot paginate this call, since `useUsersCollection` expects the whole list of collections to filter it on the client side.
  const { Collections, isLoading, nextPage, isLastPage } = useCollectionUser(address);
  const { Users } = useUsersCollection(Collections);

  function getUsers(co_creators){
    var users = [];
    for (let i=0; i<co_creators.length && i<8; i++){
      for (let y of Users){
        if (y?.get("ethAddress") == co_creators[i].address.toLowerCase()){
          users.push(y);
        }
      }
    }
     return users;
  }

  function getPath(name){
    var path = name.toLowerCase();
    path = path.replace(/ /g, "-");
    return path;
  }

  // Infinite scroll:
  // This callback will be called every time the referenced element gets created, with the element itself as an argument.
  // It will be attached to the last card.
  const observerRef = useRef(null)
  const lastCardRef = useCallback((card) => {
    if (isLastPage) return
    if (observerRef.current) observerRef.current.disconnect()
    observerRef.current = new IntersectionObserver(entries => {
        // we are just monitoring one element (the last card)
        if (entries[0].isIntersecting && !isLastPage) nextPage()
    })
    if (card) observerRef.current.observe(card)
  }, [Collections])

  return (
    <div>
      <div className="row mb-30_reset">
      {Collections.map((val, i) => (
          <div ref={Collections.length === i+1 ? lastCardRef : null} className="col-lg-4 col-md-6 col-sm-8" key={i}>
            <div className="collections space-y-10 mb-30">
              <div className="collections_item">
                <Link to={`/collection/${getPath(val?.get("name"))}`} className="images-box space-y-10">
                  <img src= {val?.get("image") ? val?.get("image").url() : `img/items/item_1.png`} alt="prv"/>
                </Link>
              </div>
              <div className="collections_footer justify-content-between">
                <h5 className="collection_title">
                  <Link to={`/collection/${getPath(val?.get("name"))}`}>{val?.get("name")}</Link>
                </h5>
                <Link to="#" className="likes space-x-3">
                  <i className="ri-heart-3-fill" />
                  <span className="txt_md"></span>
                </Link>
              </div>
              <div className="creators space-x-10">
                <span className="color_text txt_md">
                  {val?.get("items").length} items · 
                </span>
                <div className="avatars space-x-5">
                {getUsers(val?.get("co_creators")).map((x, i) => (
                  <Link to={`/user/${x?.get("ethAddress")}`}>
                    <img
                      src={x?.get("profilePic")? x?.get("profilePic").url() : `img/avatars/avatar_1.png`}
                      alt="Avatar"
                      className="avatar avatar-sm"
                    />
                  </Link>
                // <Link to="profile">
                //    <p className="avatars_name txt_sm"> @{} ... </p> 
                //  </Link> 
                ))}
                </div>
              </div>
            </div>
          </div>
        ))}
        { isLoading && <p style={{ color: "#fff", fontSize: "2rem", width: "100%", textAlign: "center" }}>Cargando...</p> }
      </div>
    </div>
  );
}

export default CollectionUser;
