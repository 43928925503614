import React from 'react';
import {Link} from 'react-router-dom';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import useDocumentTitle from '../../../components/useDocumentTitle';

const UploadType = () => {
  useDocumentTitle(' Crear');
  return (
    <div>
      <Header />
      <div className="hero__upload">
        <div className="container">
          <div className="space-y-20">
            <h1 className="text-center">Elige el tipo</h1>
            <p className="hero__text text-center">
              Selecciona "Solo" si quieres que tu coleccionable sea único o 
              "Multiple" si quieres crear varias copias del mismo coleccionable.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-8 col-md-10  col-sm-8">
            <div className="row sm:space-y-20">
              <div className="col-md-6">
              <Link to="upload">
                <div className="choose_box text-center">
                  <img
                    className="icon mb-20"
                    src="img/icons/single.svg"
                    alt="ImgPreview"
                  />
                  <div className="space-y-10">
                    <h5>Solo</h5>
                    <p className="color_text">
                      Si desea resaltar la singularidad y la individualidad de su artículo
                    </p>
                  </div>
                </div>
              </Link>
              </div>
              <div className="col-md-6">
              <Link to="#"> 
              {/* to="upload-multiple" */}
                <div className="choose_box text-center">
                  <img
                    className="icon mb-20"
                    src="img/icons/multiple.svg"
                    alt="ImgPreview"
                  />
                  <div className="space-y-10">
                    <h5>Multiple</h5>
                    <p className="color_text">
                      Si desea compartir su NFT con una gran cantidad de miembros de la comunidad
                    </p>
                  </div>
                </div>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UploadType;
