import { ContactsOutlined } from "@ant-design/icons";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";
import { useIPFS } from "./useIPFS";
import { useMoralisCloudFunction } from "react-moralis";


// pass an NFT address and token id and return the NFT details

export const useNFTsDetails = (NFTs, pageSize = 10000) => {
  const { token } = useMoralisWeb3Api();
  const { chainId } = useMoralisDapp();
  const [NFTsDetails, setNFTsDetails] = useState([]);
  const { resolveLink } = useIPFS();
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);

  const [page, setPage] = useState(1);

  const nfts = NFTs ? NFTs.slice(pageSize*(page - 1), page*pageSize) : undefined

  const { data, isLoading: isMoralisLoading, error: isError } = useMoralisCloudFunction(
    "getNFTsDetails",
    { NFTs: nfts } // FIXME: sometimes this is called with NFTs = undefined, which causes an error. However it recovers after a few seconds.
  );
  
  const isLastPage = NFTs && NFTs.length <= page*pageSize

  const nextPage = () => {
    if (!isLastPage) {
      setPage(page => page + 1)
      setIsApiLoading(true);
    }
  }

  const isLoading = !isError && (isMoralisLoading || isApiLoading);

  useEffect(async () => {
    if (data) {
      setIsApiLoading(true);
      for (let NFT of data) {
        setFetchSuccess(true);
        if (NFT?.metadata) {
          NFT.metadata = JSON.parse(NFT.metadata);
          if (NFT.metadata?.attributes[0] == null){NFT.project = 'lighthouse';}else  {      if (NFT.metadata?.attributes[0].trait_type == 'Project'){NFT.project = NFT.metadata?.attributes[0].value;}else{NFT.project ='lighthouse';}}
          
          if (NFT.metadata?.image){NFT.image = resolveLink(NFT.metadata?.image);}
          if (NFT.metadata?.animation_url){NFT.animation_url = resolveLink(NFT.metadata?.animation_url);}
        } else if (NFT?.token_uri) {
          try {
            await fetch(NFT.token_uri)
              .then((response) => response.json())
              .then((data) => {
                NFT.image = resolveLink(data.image);
              });
          } catch (error) {
            setFetchSuccess(false);
          }
        }
      }
      setNFTsDetails(prev => [...prev, ...data]);
      setIsApiLoading(false);
    }  
  }, [data]);



  return {
    NFTsDetails,
    setNFTsDetails,
    isError,
    isLoading,
    isLastPage,
    nextPage,
  };
};




