import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";
import { useIPFS } from "./useIPFS";

export const useNFTBalance = (address) => {
  const { account } = useMoralisWeb3Api();
  const { chainId } = useMoralisDapp();
  const { resolveLink } = useIPFS();
  const [NFTBalance, setNFTBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  const [nextCursor, setNextCursor] = useState(null);
  const [cursor, setCursor] = useState(null);

  const {
    fetch: getNFTBalance,
    data,
    error,
    // Cursor documentation: https://docs.moralis.io/web3-data-api/evm/reference/pagination#nodejs-example
  } = useMoralisWeb3ApiCall(account.getNFTs, { chain: chainId || "0x89", address, limit: 20, cursor , order: true, exclude_spam: true, "token_addresses": [
    "0xbdaa5d32d85fadd52594b9f4162f5d60a01df705"
  ]});

  const isLastPage = nextCursor == "" || nextCursor == null

  const nextPage = () => {
    if (!isLastPage) {
      setIsLoading(true)
      setCursor(nextCursor)
    }
  }

  const [fetchSuccess, setFetchSuccess] = useState(true);
console.log("aca6");
console.log(data);
  useEffect(async () => {
    if (data?.result) {
      setIsLoading(false)

      const NFTs = data.result;

      setNextCursor(data.cursor)

      setFetchSuccess(true);
      for (let NFT of NFTs) {
        if (NFT?.metadata) {
          NFT.metadata = JSON.parse(NFT.metadata);
          if (NFT.metadata?.attributes[0] == null){NFT.project = 'lighthouse';}else  {      if (NFT.metadata?.attributes[0].trait_type == 'Project'){NFT.project = NFT.metadata?.attributes[0].value;}else{NFT.project ='lighthouse';}}
          if (NFT.metadata?.image){NFT.image = resolveLink(NFT.metadata?.image);}
          if (NFT.metadata?.animation_url){NFT.animation_url = resolveLink(NFT.metadata?.animation_url);}
        } else if (NFT?.token_uri) {
          try {
          } catch (error) {
            setFetchSuccess(false);

/*          !!Temporary work around to avoid CORS issues when retrieving NFT images!!
            Create a proxy server as per https://dev.to/terieyenike/how-to-create-a-proxy-server-on-heroku-5b5c
            Replace <your url here> with your proxy server_url below
            Remove comments :)

              try {
                await fetch(`<your url here>/${NFT.token_uri}`)
                .then(response => response.json())
                .then(data => {
                  NFT.image = resolveLink(data.image);
                });
              } catch (error) {
                setFetchSuccess(false);
              }

 */
          }
        }
      }
      setNFTBalance(prev => {
        const newBalance = [...prev, ...NFTs];
        return newBalance
      });
      //etCursor(cursor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { getNFTBalance, NFTBalance, fetchSuccess, error, isLoading, nextPage, isLastPage };
};
