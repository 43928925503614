import React, { Fragment , useState} from 'react';
import {Link} from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useUser } from "../../hooks/useUser";

const SidebarProfile = () => {
  const { address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const [showMore, setShowMore] = useState(false);

  return (
    <Fragment>
 
      <div className='col-lg-8 col-md-8 col-sm-12 mb-40'>
        <div className='col-lg-9 col-md-8 col-sm-12'>
          <div className="space-y-10">
          <div className="space-y-20">
            <p className='txt'>
              {user?.get("description").length > 200? (
                <Fragment>
                  {!showMore ? (
                  <Fragment>
                    {user?.get("description").slice(0, 200)}...
                    <Link to="#" className='txt ' onClick={() => {setShowMore(true)}}>
                      Ver mas
                      <i class="ri-arrow-down-s-line"></i>
                    </Link>
                  </Fragment>
                ):(
                  <Fragment>
                    {user?.get("description")}
                    <Link to="#"  className='txt ' onClick={() => {setShowMore(false)}}>
                    Ver menos
                    <i class="ri-arrow-up-s-line"></i>
                  </Link>
                </Fragment>
                )}
                </Fragment>
              ):(
                user?.get("description")
              )
              }
            </p>
          </div>
          </div>
          </div>
        </div>
        
        <div className='col-lg-4 col-md-4 col-sm-12 mb-40'>
          <div className="space-y-10">
            <div className="box">
              <ul className="social_profile space-y-10 overflow-hidden">
                <li>
                <a href={`https://www.facebook.com/${user?.get("socialLinks")?.facebook}`} rel="noreferrer"  target="_blank">
                    <i className="ri-facebook-line" />
                    <span className="color_text">facebook/</span>
                    {user?.get("socialLinks")?.facebook}
                  </a>
                </li>
                <li>
                <a href={`https://www.instagram.com/${user?.get("socialLinks")?.instagram}`} rel="noreferrer"  target="_blank">
                    <i className="ri-instagram-line" />
                    <span className="color_text">instagram/</span>
                    {user?.get("socialLinks")?.instagram}
                  </a>
                </li>
                <li>
                <a href={`https://www.twitter.com/${user?.get("socialLinks")?.twitter}`} target="_blank" rel="noreferrer" >
                    <i className="ri-twitter-line" />
                    <span className="color_text">twitter/</span>
                    {user?.get("socialLinks")?.twitter}
                  </a>
                </li>
                <li>
                <a href={`https://discord.com/invite/${user?.get("socialLinks")?.discord}`}  target="_blank" rel="noreferrer" >
                    <i className="ri-discord-line" />
                    <span className="color_text">discord/</span>
                    {user?.get("socialLinks")?.discord}
                  </a>
                </li>
                <li>
                <a href={`https://www.youtube.com/${user?.get("socialLinks")?.youtube}`} target="_blank" rel="noreferrer" >
                    <i className="ri-youtube-line" />
                    <span className="color_text">youtube/</span>
                    {user?.get("socialLinks")?.youtube}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
     
    </Fragment>
  );
};

export default SidebarProfile;


