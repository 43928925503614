import React from 'react';
import {Link} from 'react-router-dom';
import { useProyectosHome } from "../../hooks/useProyectosHome";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const ProyectosHome = () => {
  const { Proyectos, fetchSuccess } = useProyectosHome();

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrow: true,
    infinite: true,
    speed: 1500,
    slidesToShow: Math.min(1,Proyectos.length),
    slidesToScroll: 1,
    swipeToSlide: true,
       
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: Math.min(1,Proyectos.length), 
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: Math.min(1,Proyectos.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(1,Proyectos.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1,Proyectos.length),
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div >
      <div className="section mt-80">
        <div className="container">
          <div className="section__head">
            {/* <div className="d-flex justify-content-between align-items-center">
              <Link to="proyectos" className="btn btn-dark btn-sm">
                Ver todo
              </Link>
            </div> */}
          </div>
          <div className="container">
          <div className="row justify-content-center mb-10_reset logos__wrap home_projects">
            <div className="col-lg-7 col-md-6 col-sm-5 puto" >
              <div className="space-y-30">
                <h1 className="text-black">Suma tu proyecto a la comunidad</h1>
                <p className="color_dark section__text">
                Contribuimos al ecosistema facilitando el acceso al mercado de productos de economías compartidas alrededor del mundo,
                a través de un marketplace especializado para tokenizar proyectos y comprar y vender tokens NFT.
                </p>
                <Link to="" className="btn btn-primary">
                  Quiero ser parte
                </Link>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-7">
              <Slider {...settings}>
                {Proyectos.map((val, i) => (
                  <div className="col-xl-4 col-lg-6 col-md-6 col-sm-8" key={i}>
                    <div className="card__item three isProject">
                      <div className="card_body space-y-10">
                        <div className="card_head">
                          <img className="item_img"src= {`${val.get("profilePic")?.url()}`} alt="prv"/>
                        </div>
                        <div className="card_bottom">
                        <h6 className="card_title">
                          <Link to={`user/${val.get("ethAddress")}`}>{val.get("username")}</Link>
                        </h6>
                        <div className="card_footer d-block space-y-10">
                          <div className="d-flex justify-content-between align-items-center">
                              <div className="creators space-x-10">
                                <p className="avatars_name txt_sm">
                                  {val.get("description")}
                                </p>
                              </div>
                          </div>
                          <div className="hr" />
                          <div className="d-flex align-items-center space-x-10">
                            <i className="ri-vip-crown-line" />
                                <p className="avatars_name txt_sm">
                                  
                                </p>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProyectosHome;




