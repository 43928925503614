import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";

// given an NFT (addr/id) it returns the cocreators of the collection it belongs to (if any)

export const useNFTCollectionUsers = (address, id) => {
    const [Users, setUsers] = useState([]);
    const [Collection, setCollection] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(true);

    const {
        // fetch: getNFTDetails,
        data,
        // error,
        // isLoading,
    } = useMoralisQuery("Collection");

    //one item : "0xcb9071754552e5649e912f52e3ca3de93077cf42/35/0x89
    useEffect(async () => {
        if (data && address && id) {
            var users = [];
            var collection = [];
            for (let i of data){
                for (let j of i.get("items")){
                    let addrItem = j.split("/")[0];
                    let idItem = j.split("/")[1];
                    if ( addrItem.toLowerCase() == address.toLowerCase() && idItem == id){
                        collection.push(i)
                        for (let k of i.get("co_creators")){
                            users.push(k.address);
                        }
                    }
                }
            }
        setFetchSuccess(true);
        setUsers(users);
        setCollection(collection);
        }
    }, [address, id]);
    
    return {
        // getNFTDetails,
        CocreatorsNFT: Users,
        Collection,
        fetchSuccess,
        // error,
        // isLoading,
    };
    }
