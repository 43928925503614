import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { MoralisProvider } from "react-moralis";
import { MoralisDappProvider} from "./providers/MoralisDappProvider/MoralisDappProvider";
import { Helmet } from "react-helmet";

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { polygon } from 'wagmi/chains'

const chains = [polygon]
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

ReactDOM.render(
  <>
    <WagmiConfig config={wagmiConfig}>
      <MoralisProvider dangerouslyUseOfMasterKey='J4X7y4U3qnUSPxocYv7uRpXpOBAcMc9KInSS1Ztp' serverUrl={process.env.REACT_APP_SERVER_URL ?? ''} appId={process.env.REACT_APP_APPLICATION_ID ?? ''}>
        <MoralisDappProvider>
          <Helmet>
            <meta charSet="utf-8" />    
          </Helmet>
          <App />
        </MoralisDappProvider>
      </MoralisProvider>
    </WagmiConfig>

    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} defaultChain={polygon} themeVariables={{ "--w3m-accent-color": "#ff00ae", "--w3m-background-color": "#ff00ae" }} />
  </>,
  document.getElementById('root')
);



