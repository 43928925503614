import env from "../providers/MoralisDappProvider/environment"

const { SECRET_KEY, KEY, CLIENT_ID } = env

export async function getUtilities(projectName) {
    const nodesUser = "admin"
    const nodesPass = "66THMQt&ld7KyF!3gUN"
    const credentials = Buffer.from(`${nodesUser}:${nodesPass}`).toString("base64")

    const res = await fetch("https://lighthouseco.work:5001/api/v1.0/nodes/", {
      headers: {
        "Authorization": `Basic ${credentials}`,
      },
    })
    const nodes = await res.json()

    console.log("proyecto a buscar utilidad)")
    console.log(projectName)
    console.log("nodes=")
    console.log(nodes)

    // Load the node that matches the project name
    const node = nodes.find(({ name }) =>
      projectName.includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName) ||
      projectName.replace(/ /g, "").includes(name.toLowerCase()) ||
      name.toLowerCase().includes(projectName.replace(/ /g, ""))
    )
    if (!node) {
      throw new Error("No se encontró el proyecto asociado a la colección seleccionada, por favorp pruebe con otra colección.")
    }
    // Load utilities from the node's endpoint
    const endpoint = node.dns.slice(0, -1)// + ":5000/" // add port
    const odoo_server = node.odoo_server
    const odoo_port = node.odoo_port
    const odoo_db = node.odoo_db

    const res2 = await fetch(endpoint + `/utility?client_id=${CLIENT_ID}&secret_key=${SECRET_KEY}&api_key=${KEY}&odoo_server=${odoo_server}&odoo_port=${odoo_port}&db=${odoo_db}`)
    const utilities = await res2.json()
    console.log("aca las utilidades del proyecto")
    console.log(utilities)
    return utilities
}