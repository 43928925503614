import React from 'react';
import CardProfile from '../../../components/cards/CardProfile';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroProfile from '../../../components/hero/HeroProfile';
import SidebarProfile from '../../../components/sidebars/SidebarProfile';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import CollectionProfile from '../../../components/collection/CollectionProfile';
import ProyectosProfile from '../../../components/collection/ProyectosProfile';
import useDocumentTitle from '../../../components/useDocumentTitle';
import {Link} from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { useUser } from "../../../hooks/useUser";

const Profile = () => {
  useDocumentTitle('Profile ');
  const { isConnected: isAuthenticated, address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]

  if (isAuthenticated){
    return (
        <div>
          <Header />
          <HeroProfile />
          <div className="container">
            <div className="row justify-content-center">
              <SidebarProfile />
            </div>
            <div className="row justify-content-center">
                <div className="profile__content">
                  <div className="justify-content-between">
                    <Tabs className="space-x-10">
                      <div className="d-flex justify-content-between">
                        <TabList className="d-flex space-x-10 mb-30 nav-tabs">
                          <Tab className="nav-item">
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-1"
                              role="tab">
                              Colecciones
                            </Link>
                          </Tab>
                          <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-2"
                              role="tab">
                              Creaciones
                            </Link>
                          </Tab>
                          {!user?.get("isProject") && (
                            <Tab>
                            <Link
                              className="btn btn-white btn-sm"
                              data-toggle="tab"
                              to="#tabs-3"
                              role="tab">
                              Proyectos
                            </Link>
                          </Tab>
                            )}
                        </TabList>
                      </div>
                      
                      <div className="tab-content">
                        <TabPanel className="active">
                          <CollectionProfile />
                        </TabPanel>
                        <TabPanel>
                          <CardProfile />
                        </TabPanel>
                        {!user?.get("isProject") && (
                            <TabPanel>
                            <ProyectosProfile />
                            </TabPanel>
                            )}
                      </div>
                    </Tabs>
                  </div>
                </div>
            </div>
          </div>
          <Footer />
        </div>
  );
  }else{
    return <Redirect to="/" />
  }
  
};

export default Profile;
