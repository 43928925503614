import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import { useMoralis, useMoralisQuery } from "react-moralis";
import { useUser } from "../../hooks/useUser";
import TopArtistCollection from '../../components/creators/TopArtistCollection';
import Skeleton from 'react-loading-skeleton'

const HeroCollection = ({Collection}) => {

  const ref = useRef();
  const [isActive, setActive] = useState(false);
  const toggleFollow = () => {
    setActive(!isActive);
  };
  const [isShare, setShare] = useState(false);

  const toggleShare = () => {
    setShare(!isShare);
  };
  const [isMore, setMore] = useState(false);

  const toggleMore = () => {
    setMore(!isMore);
  };
//   bybg52089381
  return (
    <div className="mb-0">
      <div className="hero__profile">
        <div className="cover" style={!Collection ? {marginTop: "-5px"} : {}}>
          {Collection ? <img src={Collection?.get("banner")?.url()} /> : <Skeleton height={"100%"} />}
        </div>
        <div className="infos">
          <div className="container">
            <div className="row flex-wrap align-items-center justify-content-between sm:space-y-50">
            <div className="col-md-auto mr-20">
                <div className="avatars d-flex space-x-20 align-items-center">
                  <div className="avatar_wrap">
                    {Collection ? 
                      <img style={{ "margin-left": "20px"}}
                        className="avatar avatar-lg collection"
                        src= {Collection?.get("image")?.url()}
                      />
                    :
                      <Skeleton className="avatar avatar-lg collection" style={{ marginLeft: "20px" }} />
                    }
                  </div>
                  {Collection ?
                    <h2 style={{ "margin-left": "60px"}}>{Collection?.get("name")}</h2>
                  :
                    <Skeleton style={{ marginLeft: "60px", width: "300px", fontSize: "2rem" }} />
                  }
                  {Collection?.get("isWhitelisted") && 
                    <div className="badge"><img src="/img/icons/Badge.svg" alt="icons"/></div>
                  }
                </div>
              </div>

              <div className="col-md-auto">
                <div className="d-sm-flex flex-wrap align-items-center space-x-20 mb-20_reset d-sm-block">
                  <div className="d-flex flex-wrap align-items-center space-x-20">
                    <div className="mb-20">
                      <div className='collection_header'>
                        <ul className="footer__social space-x-10">
                          <li>
                            <a href={`https://www.facebook.com/${Collection?.get("socialLinks")?.facebook}`} rel="noreferrer"  target="_blank">
                              <i className="ri-facebook-line" />
                            </a>
                          </li>
                          <li>
                            <a href={`https://www.instagram.com/${Collection?.get("socialLinks")?.instagram}`} rel="noreferrer"  target="_blank">
                              <i className="ri-instagram-line" />
                            </a>
                          </li>
                          <li>
                            <a href={`https://www.twitter.com/${Collection?.get("socialLinks")?.twitter}`} target="_blank" rel="noreferrer" >
                              <i className="ri-twitter-line"  />
                            </a>
                          </li>
                          <li>
                            <a href={`https://discord.com/invite/${Collection?.get("socialLinks")?.discord}`} target="_blank" rel="noreferrer" >
                              <i className="ri-discord-line" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCollection;
