import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link} from 'react-router-dom';
import { useUsersCollection } from "../../hooks/useUsersCollection";
import Skeleton from 'react-loading-skeleton';

export default function TopArtistCollection({Collection}) {

  const { Users } = useUsersCollection([Collection]);


  const settings = {
    autoplay: true,
    autoplaySpeed: 2200,
    dots: false,
    arrow: true,
    infinite: true,
    speed: 1200,
    slidesToShow: Users.length === 0 ? 4 : Math.min(4, Users.length),
    slidesToScroll: 1,
    margin: 20,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: Users.length === 0 ? 4 : Math.min(4, Users.length),
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: Users.length === 0 ? 3 : Math.min(3, Users.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Users.length === 0 ? 2 : Math.min(2, Users.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Users.length === 0 ? 1 : Math.min(1, Users.length),
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div className="section__artists mt-20">
      <div className="container">
        <div className="space-y-30">
          <div className="section_head">
            <p className="section__title_md mt-10">Co-Creadores</p>
          </div>
          <div className="section_body swiper_artists">
            <Slider {...settings}>
              {Users.length === 0 &&
                Array(4).fill(0).map((_, i) => (
                  <div className="item" key={i}>
                    <div className="creator_item creator_card rounded_border space-x-10">
                      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <Skeleton circle style={{ width: "5rem", height: "5rem" }} inline />
                        <Skeleton style={{ width: "150px", height: "1.5rem", marginLeft: "1rem" }} />
                      </div>
                    </div>
                  </div>
                ))
              }
              {Users.map((val, i) => (
                <div className="item" key={i}>
                  <div className="creator_item creator_card rounded_border space-x-10">
                    <div className="avatars space-x-10">
                      <div className="media">
                        {val?.get("isProject") && (
                           <div className="badge">
                           <img src={`/img/icons/Badge.svg`} alt="icons" />
                         </div>
                          )}
                        <Link to={`/user/${val?.get("ethAddress")}`}>
                          <img
                            src={val?.get("profilePic") ? val?.get("profilePic")?.url() : `/img/avatars/avatar_1.png`}
                            alt="Avatar"
                            className="avatar avatar-md"
                          />
                        </Link>
                      </div>
                      <div>
                        <Link to={`/user/${val?.get("ethAddress")}`}>
                          <p className="avatars_name color_black">
                            {val?.get("username")?.length > 15 ? (
                              val?.get("username").substring(0, 15) + "..."
                            ) : ( 
                              val?.get("username")
                            )}
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
