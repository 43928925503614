import React from "react";
import { Link } from "react-router-dom";
function Call2action() {
  return (
    <div>
      <div className="call2action">
        <div className="container">
          <div
            className="row justify-content-between align-items-center
                        sm:space-y-20"
          >
            <div className="col-md-6">
              <div className="space-y-20">
                <h1 className="text-white">Empieza tu colección hoy</h1>
                <p className="color_light section__text">
                  {/* Contribuimos al ecosistema facilitando el acceso al mercado de productos de economías compartidas alrededor del mundo,
                a través de un marketplace especializado para tokenizar proyectos y comprar y vender tokens NFT. */}
                  Te ofrecemos la oportunidad única de co-crear valor a través
                  de una colección de NFTs que impulse el desarrollo de tú
                  comunidad y potencie sus sueños y aspiraciones.
                </p>
                <Link
                  to="https://docs.google.com/forms/d/12gV8Ypw5Qk1MtMCRkd6-zDL5miHYRGJ47aLUQZEALMI/edit"
                  className="btn btn-primary"
                >
                  Empezar colección
                </Link>
              </div>
            </div>
            <div className="col-md-auto">
              <img
                className="img-fluid img__logo"
                alt="rr"
                src={`img/logos/Logo_illustrstion.png`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Call2action;
