import React from 'react';
import {Link} from 'react-router-dom';
import { useCollectionUser } from "../../hooks/useCollectionUser";
import { useCreatorCollection } from "../../hooks/useCreatorCollection";
import { useAccount } from 'wagmi';
import { useUser } from "../../hooks/useUser";


function ProyectosProfile() {
  const { address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const { Collections, isLoading } = useCollectionUser(user?.get("ethAddress"));
  const { Users } = useCreatorCollection(Collections);

  return (
    <div>
      <div className="row mb-30_reset">
        {isLoading ?
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <img src="img/loader.gif" alt="" />
          </div>
        : (Users.length === 0 &&
            <h1>No hay resultados</h1>
          )
        }
        {Users.map((val, i) => (
            val?.get("isProject") && (
          <div className="col-lg-4 col-md-6 col-sm-8" key={i}>
            <div className="collections space-y-10 mb-30">
              <Link to="collections">
                <div className="collections_item">
                  <Link to={`/user/${val.get("ethAddress")}`} className="images-box space-y-10">
                    <img src={val.get("profilePic")?.url()} />
                  </Link>
                </div>
              </Link>
              <div className="collections_footer justify-content-between">
                <h5 className="collection_title">
                  <Link to={`/user/${val.get("ethAddress")}`}>{val.get("username")}</Link>
                </h5>
                <Link to="#" className="likes space-x-3">
                  <i className="ri-heart-3-fill" />
                  <span className="txt_md"></span>
                </Link>
              </div>
            </div>
          </div>
          )
        ))}
      </div>
    </div>
  );
}

export default ProyectosProfile;
