import React from 'react';
import {Link} from 'react-router-dom';
import { useCollectionsHome } from "../../hooks/useCollectionsHome";
import { useUsersCollection } from "../../hooks/useUsersCollection";
import Slider from 'react-slick';

const CollectionsHome = () => {
  const { Collections, fetchSuccess } = useCollectionsHome();
  
  const { Users } = useUsersCollection(Collections);

  const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1200,
    slidesToShow: Math.min(3,Collections?.length),
    slidesToScroll: 1,
    pauseOnHover: false,
    margin: 20,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(2,Collections?.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1,Collections?.length),
          slidesToScroll: 1,
        },
      },
    ],
  };


  function getUsers(co_creators){
    var users = [];
    for (let i=0; i<co_creators.length && i<8; i++){
      for (let y of Users){
        if (y?.get("ethAddress") == co_creators[i].address.toLowerCase()){
          users.push(y);
        }
      }
    }
     return users;
  }

  function getPath(name){
    var path = name.toLowerCase();
    path = path.replace(/ /g, "-");
    return path;
  }

  return (
    <div>
      <div className="section mt-70">
        <div className="container">
          <div className="section__head">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="section__title"> Colecciones</h2>
              <Link to="/collections" className="btn btn-dark btn-sm">
                Ver todo
              </Link>
            </div>
          </div>
          <div>
      <div className="row justify-content-center mb-30_reset">
        <Slider {...settings}>
          {Collections.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="collections space-y-10 mb-30 mx-15">
                <div className="collections_item">
                  <Link to={`/collection/${getPath(val?.get("name"))}`} className="images-box space-y-10">
                    <img src= {val?.get("image") ? val?.get("image").url() : `img/items/item_1.png`} alt="prv"/>
                  </Link>
                </div>
                <div className="collections_footer justify-content-between">
                  <h5 className="collection_title">
                    <Link to={`/collection/${getPath(val?.get("name"))}`}>{val?.get("name")}</Link>
                  </h5>
                  <Link to="#" className="likes space-x-3">
                    <i className="ri-heart-3-fill" />
                    <span className="txt_md"></span>
                  </Link>
                </div>
                <div className="creators space-x-10">
                  <span className="color_text txt_md">
                    {val?.get("items").length} items · 
                  </span>
                  <div className="avatars space-x-5">
                  {getUsers(val?.get("co_creators")).map((x, i) => (
                    <Link to={`/user/${x.get("ethAddress")}`}>
                      <img
                        src={x?.get("profilePic")? x?.get("profilePic").url() : `img/avatars/avatar_1.png`}
                        alt="Avatar"
                        className="avatar avatar-sm"
                      />
                    </Link>
                  ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionsHome;
