import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { useMoralisCloudFunction } from "react-moralis";

export const useProyectos = () => {
  const [Proyectos, setProyectos] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);

    const { data } = useMoralisCloudFunction(
      "getProject",
    );

  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      setProyectos(data);
    }
  }, [data]);



  return {
    // getNFTDetails,
    Proyectos,
    fetchSuccess,
    // error,
    // isLoading,
  };
};


