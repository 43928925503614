import "./assets/css/plugins/bootstrap.min.css";
import 'remixicon/fonts/remixicon.css'
import "./assets/scss/style.scss";
import { createContext, useState } from "react";
import Router from "./Router/routes";
import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState("dark");

  return (
    <ThemeContext.Provider value={{theme, setTheme}}>
    <div className="App overflow-hidden" id={theme}>
      <SkeletonTheme baseColor="#313131" highlightColor="#525252">
        <Router />
      </SkeletonTheme>
    </div>
    </ThemeContext.Provider>
  );
}

export default App;
