import React from 'react';
import ProyectosList from '../../../components/collection/ProyectosList';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroProyectos from '../../../components/hero/HeroProyectos';
import useDocumentTitle from '../../../components/useDocumentTitle';

const Proyectos = () => {
  useDocumentTitle(' Proyectos');
  return (
    <div>
      <Header />
      <HeroProyectos />
      <div className="section mt-60">
        <div className="container">
          <ProyectosList />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Proyectos;
