import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { useMoralisCloudFunction } from "react-moralis";

export const useUsersHome = () => {
  const [Users, setUsers] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);

    const { data } = useMoralisCloudFunction(
      "getUserWhitelisted",
    );



  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      setUsers(data);
    }
  }, [data]);



  return {
    // getNFTDetails,
    Users,
    fetchSuccess,
    // error,
    // isLoading,
  };
};
