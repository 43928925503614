import {React, useRef} from 'react';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { useNFTsDetails } from "../../../hooks/useNFTsDetails";
import { getExplorer } from "../../../helpers/networks";
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import { useWeb3ExecuteFunction } from "react-moralis";
import {useMoralis, useMoralisQuery, useNewMoralisObject } from "react-moralis";
import { useState } from 'react';
import { Card, Image, Tooltip, Modal, Input, Alert, Spin, Button, Badge } from "antd";
import { toast, ToastContainer } from 'react-toastify';


function CardEditCollection({Collection}) {

  const { NFTsDetails, setNFTsDetails } = useNFTsDetails(Collection?.get("items"));

  const ref = useRef();
  const closeTooltip = () => ref.current.close();

  const [visible, setVisibility] = useState(false);
  const [nftToDelete, setNftToDelete] = useState(null);
  const [loading, setLoading] = useState(false);

  const contractProcessor = useWeb3ExecuteFunction();
  const { chainId } = useMoralisDapp();
  const { Moralis } = useMoralis();
  
  async function deleteNFT() {
    var items = Collection.get("items");
    for (var i = 0; i < items.length; i++) {
      let item = items[i].split("/");
      if (item[0].toLowerCase() == nftToDelete.token_address.toLowerCase() && item[1] == nftToDelete.token_id) {
        items.splice(i, 1);
        Collection.set("items", items);
        setNFTsDetails(NFTsDetails.filter((nft) => nft.token_id != nftToDelete.token_id));
        setVisibility(false);
      }
    }
  }

  const handleDeleteClick = (nft) => {
    setNftToDelete(nft);
    setVisibility(true);
  };

  function succDelete() {
    toast.success("You have deletec this NFT");
  }

  function failDelete() {
    toast.error("There was a problem when deleting this NFT");
  }


  return (
    <div>
      <div className="row mb-30_reset">
        {NFTsDetails.map((nft, i) => (
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={i}>
            <div className="card__item four">
              <div className="card_body space-y-10">
                <div className="card_head">
                <Link to={`/item-details/${nft.token_address}/${nft.token_id}`}>
                {nft.animation_url ? (
                  <video
                  // autoPlay
                  // loop
                  type="video/mp4"
                  style={{width: "100%"}}
                  className="item_img"
                  src= {nft?.animation_url}
                />
              ) : (
                <img
                className="item_img"
                src= {nft?.image}
                />  
                )}
              </Link>
                  <Link to="#" className="likes space-x-3">
                    <i className="ri-heart-3-fill" />
                    <span className="txt_sm"></span>
                  </Link>
                  <div className="action">
                    <button className="btn btn-sm btn-primary btn_auction" onClick={() => handleDeleteClick(nft)} >
                        <i class="ri-delete-bin-6-fill"></i>
                          Eliminar
                    </button>
                  </div>
                </div>
                {/* =============== */}
                <div className="card_bottom">
                <h6 className="card_title">
                <Link className="color_black" to={`/item-details/${nft.token_address}/${nft.token_id}`}>
                  {nft?.metadata?.name ? (
                    nft?.metadata?.name.length > 25 ? (
                      nft.metadata.name.substring(0, 25) + "..."
                    ) : (
                      nft.metadata.name
                    )
                    ) : (
                      nft.symbol
                    )}
                </Link>
                </h6>
                <div className="card_footer d-block space-y-10">
                <div className=" d-flex justify-content-between align-items-center">
                  <div className="creators space-x-10">
                    <Link to="profile">
                      <p className="avatars_name txt_sm">
                        {nft?.token_id?.length > 7 ? (
                        "#" + nft.token_id.substring(0, 7) + "..."
                        ) : (
                        "#" + nft.token_id
                        )}
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="hr" />
                <div className="d-flex align-items-center space-x-10" onClick={() =>
                      window.open(
                        `${getExplorer(chainId)}address/${nft.token_address}`,
                        "_blank"
                      )
                    }>
                  <i className="ri-vip-crown-line" />
                      <p className="avatars_name txt_sm">
                        View on explorer
                      </p>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
            title={`Delete ${nftToDelete?.name} #${nftToDelete?.token_id}`}
            visible={visible}
            onCancel={() => setVisibility(false)}
            onOk={() => deleteNFT()}
            okText="Delete"
          >
            <Spin spinning={loading}>
              <div
                style={{
                  width: "250px",
                  margin: "auto",
                }}
              >
                <img
                src={nftToDelete?.image}
                style={{
                    width: "250px",
                    borderRadius: "10px",
                    marginBottom: "15px",
                }}
                />
              </div>
            </Spin>
      </Modal>
    </div>
  );
}

export default CardEditCollection;
