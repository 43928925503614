import React from 'react';
import {Link} from 'react-router-dom';
import { useMoralis, useMoralisFile } from "react-moralis";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HeroEditProfile = ({user, setBannerPic, bannerPic}) => {

  const { saveFile, moralisFile } = useMoralisFile();

  const uploadFile = (fileTarget) => {
    // save to moralis
    setBannerPic(fileTarget);
    saveFile("photo.jpg", fileTarget, {
      type: "image/png",
      onSuccess: (result) => {
      user.set("bannerPic", result);
      user.save().then(
        () => {
          toast('Profile updated');
        },
        (error) => {
          toast.error(error.message);
        }
      );
    },
      onError: (error) => console.log(error),
    });
  };


  return (
    <div className="mb-50">
      <div className="hero__profile">
        <div className="tabs">
          <nav aria-label="breadcrumb">
            {/* <ol className="breadcrumb default mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Edit profile
              </li>
            </ol> */}
            <div id="boxUpload">
                <div className="btn btn-light">
                  Upload  Photo
                </div>
                <input 
                  id="imageUpload"
                  type="file"
                  name="profile_photo"
                  placeholder="Photo"
                  required
                  capture
                  onChange={(e) => uploadFile(e.target.files[0])}
                />
            </div>
          </nav>
        </div>
        <div className="cover">
        {bannerPic == 'defaultbanner' ? (
          <img src={user?.get("bannerPic") ? user?.get("bannerPic").url() : "img/bg/cover.jpg"} alt="cover" />
          ) : (
            <img src = {URL.createObjectURL(bannerPic)}/>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroEditProfile;
