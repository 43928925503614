import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { useMoralisCloudFunction } from "react-moralis";

export const useCollectionsHome = () => {
  const [Collections, setCollecitons] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);

  const {
    // fetch: getNFTDetails,
    data,
    // error,
    // isLoading,
  } = useMoralisQuery(
    "Collection",
    (query) => query.equalTo("isWhitelisted", true),
    [],
  );





  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      setCollecitons(data);
    }
  }, [data]);



  return {
    // getNFTDetails,
    Collections,
    fetchSuccess,
    // error,
    // isLoading,
  };
};
