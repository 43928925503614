import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import MoralisDappContext from "./context";
import abi from "./marketplaceABI";
import { useAccount } from "wagmi";

function MoralisDappProvider({ children }) {
  const { Moralis } = useMoralis();
  const { address: walletAddress } = useAccount();
  const [chainId, setChainId] = useState();
  const [contractABI, setContractABI] = useState(abi); //Smart Contract ABI here
  const [marketAddress, setMarketAddress] = useState('0x137c506965dE4Ca9569213b5FE4fCa6302BC67d8'); //Smart Contract Address Here
  const [minterAddress, setMinterAddress] = useState('0xBDAa5D32d85fADd52594b9f4162F5D60a01df705'); //Smart Contract Address Here
  const [usdcAddress, setUsdcAddress] = useState('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'); //Smart Contract Address Here

  useEffect(() => {
    Moralis.onChainChanged(function (chain) {
      setChainId(chain);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MoralisDappContext.Provider value={{ walletAddress, chainId: chainId || "0x89", marketAddress, setMarketAddress, minterAddress, setMinterAddress, contractABI, setContractABI, usdcAddress }}>
      {children}
    </MoralisDappContext.Provider>
  );
}

function useMoralisDapp() {
  const context = React.useContext(MoralisDappContext);
  if (context === undefined) {
    throw new Error("useMoralisDapp must be used within a MoralisDappProvider");
  }
  return context;
}

export { MoralisDappProvider, useMoralisDapp };
