import React from 'react';
import EditCollection from '../../../components/collection/CollectionManage';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import HeroCollections from '../../../components/hero/HeroCollections';
import useDocumentTitle from '../../../components/useDocumentTitle';
import {Link} from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useAccount } from 'wagmi';

const ManageCollections = () => {
  useDocumentTitle('Manage');
  const { isConnected: isAuthenticated } = useAccount();

  if (isAuthenticated){
  return (
    <div>
      <Header />
      <div className="hero__collections">
            <div className="container">
                <h1>Mis colecciones</h1>
                <Link to="/create-collection" className="btn btn-primary mt-30">Crear colección</Link>  
            </div>
      </div>
      <div className="section mt-60">
        <div className="container">
          <EditCollection />
        </div>
      </div>
      <Footer />
    </div>
  );
  } else{
    return <Redirect to="/" />
  }
};

export default ManageCollections;

