import { ContactsOutlined } from "@ant-design/icons";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";
import { useIPFS } from "./useIPFS";
import { useMoralisCloudFunction } from "react-moralis";


// pass an NFT address and token id and return the NFT transfers

export const useNFTTransfers = (addr, id) => {
  const { token } = useMoralisWeb3Api();
  const { chainId } = useMoralisDapp();
  const [NFTTransfers, setNFTTransfers] = useState([]);
  const { resolveLink } = useIPFS();
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const {
    // fetch: getNFTDetails,
    data,
    // error,
    // isLoading,
  } = useMoralisWeb3ApiCall(token.getWalletTokenIdTransfers, {
    address: addr,
    token_id: id,
    chain: chainId|| "0x89",
  });


  useEffect(async () => {
    if (data) {
      const transfers = {"list": data.result};
      for (let tr of transfers.list){
        if (tr.from_address == "0x0000000000000000000000000000000000000000"){
          transfers.creator = tr.to_address;
        }
      }

      // const addresses = [];
      // addresses.push(transfers.creator);
      // console.log(transfers);
      // for (let i of transfers.list){
      //   addresses.push(i.from_address);
      //   addresses.push(i.to_address);
      // }
      // const { data2 } = useMoralisCloudFunction(
      //   "getUsers",
      //   { addresses: addresses }
      // );

      // const users = data2;

      // if (users) {
      //   const res =[];
      //   for (let x of data){
      //     res.push(x[0]);
      //   }
       
      //   for(let a of res){
      //     if (transfers.creator == a.get("ethAddress")){
      //         transfers.creator = a;
      //     }
      //   }
      //   for (let i of transfers){
      //     for(let j of res){
      //         if (i.from_address == j.get("ethAddress")){
      //             i.from_address = j;
      //         }
      //         if (i.to_address == j.get("ethAddress")){
      //             i.to_address = j;
      //         }
      //     }
      //   }
      // }

      setNFTTransfers(transfers);
      setFetchSuccess(true);
    }
  }, [data]);



  return {
    // getNFTDetails,
    NFTTransfers,
    // fetchSuccess,
    // error,
    // isLoading,
  };
};
