import React from 'react';
import {Link} from 'react-router-dom';

const LeftMenu = [
  // {
  //   icon: 'gallery',
  //   title: ' Mis Colecciones',
  //   link: '/manage-collection',
  // },
  {
    icon: 'upload',
    title: ' Crear NFT',
    link: '/upload',
  },
  {
    icon: 'edit',
    title: 'Editar Perfil',
    link: '/edit-profile',
  },
  {
    icon: 'question',
    title: 'Faq',
    link: '/faq',
  },
  // {
  //   icon: 'line-chart',
  //   title: 'Activity',
  //   link: '/activity',
  // },
  // {
  //   icon: 'gallery',
  //   title: 'Item details',
  //   link: '/item-details',
  // },
  // {
  //   icon: 'upload',
  //   title: 'Upload Types',
  //   link: '/upload-type',
  // },
  // {
  //   icon: 'auction',
  //   title: 'Live Auctions',
  //   link: '/live-auctions',
  // },
  // {
  //   icon: 'mail-open',
  //   title: 'Newsletter',
  //   link: '/newsletter',
  // },
  // {
  //   icon: 'discuss',
  //   title: 'Forum & community',
  //   link: '/forum',
  // },
  // {
  //   icon: 'chat-check',
  //   title: 'Forum details',
  //   link: '/post-details',
  // },
  // {
  //   icon: 'file-search',
  //   title: 'No Result',
  //   link: '/no-results',
  // },
  // {
  //   icon: 'customer-service-2',
  //   title: 'Contact',
  //   link: '/contact',
  // },
];
const RightMenu = [
  {
    icon: 'layout',
    title: 'Próximos Proyectos',
    link: '/upcoming-projects',
  },
  // {
  //   icon: 'wallet-3',
  //   title: 'Connect wallet',
  //   link: '/connect-wallet',
  // },
  // {
  //   icon: 'share-forward',
  //   title: 'Submit request',
  //   link: '/submit-request',
  // },
  // {
  //   icon: 'message-3',
  //   title: 'Chat',
  //   link: '/chat',
  // },
  // {
  //   icon: 'layout',
  //   title: 'Blog',
  //   link: '/blog',
  // },
  // {
  //   icon: 'newspaper',
  //   title: 'Article',
  //   link: '/article',
  // },
  // {
  //   icon: 'lock',
  //   title: 'Register',
  //   link: '/register',
  // },
  // {
  //   icon: 'shield-user',
  //   title: 'Login',
  //   link: '/Login',
  // },
  // {
  //   icon: 'funds',
  //   title: 'Ranking',
  //   link: '/ranking',
  // },
  // {
  //   icon: 'file-text',
  //   title: 'Privacy Policy',
  //   link: '/privacy-policy',
  // },
  // {
  //   icon: 'file-damage',
  //   title: '404',
  //   link: '/404',
  // },
];

function MegaMenu() {
  return (
    <div>
      <div className="row sub_menu_row">
        <div className="col-lg-6 space-y-10">
        {/* <input type="checkbox" id="switch7" />
            <label htmlFor="switch7" className="ml-0">
                Toggle
            </label>
            <span>Night Mode</span> */}
          {LeftMenu.map((val, i) => (
            <li key={i}>
              <Link to={val.link}>
                <i className={`ri-${val.icon}-line`} />
                {val.title}
              </Link>
            </li>
          ))}
            <li>
               <Link to={{ pathname: "https://quickswap.exchange/#/swap" }} target="_blank" >
                <i class="ri-exchange-line"></i>
                Swap
               </Link>
            </li>
        </div>
        {/* <div className="col-lg-6 space-y-10">
          {RightMenu.map((val, i) => (
            <li key={i} >
              <Link to={val.link}>
                <i className={`ri-${val.icon}-line`} />
                {val.title}
              </Link>
            </li>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default MegaMenu;
