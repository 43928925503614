import React, {useRef, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { useMoralis } from "react-moralis";
import { Card, Image, Tooltip, Modal, Input, Alert, Spin, Button } from "antd";
import { useNFTBalance } from "../../hooks/useNFTBalance";
import { useMoralisDapp } from "../../providers/MoralisDappProvider/MoralisDappProvider";
import { getExplorer } from "../../helpers/networks";
import { useWeb3ExecuteFunction } from "react-moralis";
const { Meta } = Card;


const styles = {
  NFTs: {
    display: "flex",
    flexWrap: "wrap",
    WebkitBoxPack: "start",
    justifyContent: "flex-start",
    margin: "0 auto",
    maxWidth: "1000px",
    gap: "10px",
  },
};

function CardUser({address}) {
  const { NFTBalance, isLoading, nextPage, isLastPage } = useNFTBalance(address);
  const { chainId, marketAddress, contractABI } = useMoralisDapp();
  const { Moralis } = useMoralis();
  const [visible, setVisibility] = useState(false);
  const [nftToSend, setNftToSend] = useState(null);
  const [price, setPrice] = useState(1);
  const [loading, setLoading] = useState(false);
  const contractProcessor = useWeb3ExecuteFunction();
  const contractABIJson = JSON.parse(contractABI);
  const listItemFunction = "createMarketItem";
  const ItemImage = Moralis.Object.extend("ItemImages");

  async function list(nft, listPrice) {
    setLoading(true);
    const p = listPrice * ("1e" + 18);
    const ops = {
      contractAddress: marketAddress,
      functionName: listItemFunction,
      abi: contractABIJson,
      params: {
        nftContract: nft.token_address,
        tokenId: nft.token_id,
        price: String(p),
      },
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        console.log("success");
        setLoading(false);
        setVisibility(false);
        addItemImage();
        succList();
      },
      onError: (error) => {
        setLoading(false);
        failList();
      },
    });
  }


  async function approveAll(nft) {
    setLoading(true);  
    const ops = {
      contractAddress: nft.token_address,
      functionName: "setApprovalForAll",
      abi: [{"inputs":[{"internalType":"address","name":"operator","type":"address"},{"internalType":"bool","name":"approved","type":"bool"}],"name":"setApprovalForAll","outputs":[],"stateMutability":"nonpayable","type":"function"}],
      params: {
        operator: marketAddress,
        approved: true
      },
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        console.log("Approval Received");
        setLoading(false);
        setVisibility(false);
        succApprove();
      },
      onError: (error) => {
        setLoading(false);
        failApprove();
      },
    });
  }

  const handleSellClick = (nft) => {
    setNftToSend(nft);
    setVisibility(true);
  };

  function succList() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Success!",
      content: `Your NFT was listed on the marketplace`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function succApprove() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: "Success!",
      content: `Approval is now set, you may list your NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failList() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem listing your NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failApprove() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem with setting approval`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function addItemImage() {
    const itemImage = new ItemImage();

    itemImage.set("image", nftToSend.image);
    itemImage.set("nftContract", nftToSend.token_address);
    itemImage.set("tokenId", nftToSend.token_id);
    itemImage.set("name", nftToSend.name);

    itemImage.save();
  }


  const ref = useRef();
  const closeTooltip = () => ref.current.close();
  const [inputValue, setInputValue] = useState("explore");

  // Infinite scroll:
  // This callback will be called every time the referenced element gets created, with the element itself as an argument.
  // It will be attached to the last card.
  const observerRef = useRef(null)
  const lastCardRef = useCallback((card) => {
    if (isLastPage) return
    if (observerRef.current) observerRef.current.disconnect()
    observerRef.current = new IntersectionObserver(entries => {
        // we are just monitoring one element (the last card)
        if (entries[0].isIntersecting && !isLastPage) nextPage()
    })
    if (card) observerRef.current.observe(card)
  }, [NFTBalance])

  return (
    <div className="row mb-30_reset">
      {NFTBalance.length === 0 && !isLoading && (
        <h1>No hay resultados</h1>
      )}
      {NFTBalance.map((nft, index) => (
        <div ref={NFTBalance.length === index+1 ? lastCardRef : null} className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={index}>
          <div className="card__item three">
            <div className="card_body space-y-10">
              {/* =============== */}
              <div className="card_head">
              <Link to={`/item-details/${nft.token_address}/${nft.token_id}`}>
                {nft.animation_url ? (
                  <video
                  // autoPlay
                  // loop
                  type="video/mp4"
                  style={{width: "100%"}}
                  className="item_img"
                  src= {nft?.animation_url}
                />
              ) : (
                <img
                className="item_img"
                src= {nft?.image}
                />  
                )}
              </Link>
              </div>
              {/* =============== */}
              <div className="card_bottom">
              <h6 className="card_title">
                <Link className="color_black" to={`/item-details/${nft.token_address}/${nft.token_id}`}>
                {nft?.metadata?.name ? (
                  nft?.metadata?.name.length > 25 ? (
                    nft.metadata.name.substring(0, 25) + "..."
                  ) : (
                    nft.metadata.name
                  )
                  ) : (
                    nft.symbol
                  )}
                </Link>
              </h6>
              <div className="card_footer d-block space-y-10">
                <div
                  className="
															d-flex
															justify-content-between
															align-items-center
														">
                  <div className="creators space-x-10">
                    {/* <Link to="profile">
                      <p className="avatars_name txt_sm">
                        {"name"} ..
                      </p>
                    </Link> */}
                    <Link to="profile">
                    <p className="avatars_name txt_sm">
                        {nft?.token_id?.length > 7 ? (
                        "#" + nft.token_id.substring(0, 7) + "..."
                        ) : (
                        "#" + nft.token_id
                        )}
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="hr" />
                <div className="d-flex align-items-center space-x-10" onClick={() =>
                      window.open(
                        `${getExplorer(chainId)}address/${nft.token_address}`,
                        "_blank"
                      )
                    }>
                  <i className="ri-vip-crown-line" />
                      <p className="avatars_name txt_sm">
                        View on explorer
                      </p>
                      <p className="avatars_name txt_sm ">
                        x{nft.amount}
                      </p>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      { isLoading && <p style={{ color: "#fff", fontSize: "2rem", width: "100%", textAlign: "center" }}>Cargando...</p> }
    </div>
  );
};

export default CardUser;
