import React from 'react';
import {Link} from 'react-router-dom';
import { useCollectionUser } from "../../hooks/useCollectionUser";
import { useUsersCollection } from "../../hooks/useUsersCollection";
import { useAccount } from 'wagmi';
import { useUser } from "../../hooks/useUser";

function CollectionProfile() {
  const { address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  // Cannot paginate this call, since `useUsersCollection` expects the whole list of collections to filter it on the client side.
  const { Collections, isLoading } = useCollectionUser(user?.get("ethAddress"));
  const { Users } = useUsersCollection(Collections);

  function getUsers(co_creators){
    var users = [];
    for (let i=0; i<co_creators.length && i<8; i++){
      for (let y of Users){
        if (y?.get("ethAddress") == co_creators[i].address.toLowerCase()){
          users.push(y);
        }
      }
    }
     return users;
  }

  function getPath(name){
    var path = name.toLowerCase();
    path = path.replace(/ /g, "-");
    return path;
  }

  return (
    <div>
      <div className="row mb-30_reset">
        {isLoading ?
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <img src="img/loader.gif" alt="" />
          </div>
        : (Collections.length === 0 &&
            <h1>No hay resultados</h1>
          )
        }
        {Collections.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-8" key={i}>
            <div className="collections space-y-10 mb-30">
              <Link to="collections">
                <div className="collections_item">
                  <Link to={`/collection/${getPath(val?.get("name"))}`} className="images-box space-y-10">
                    {/* <div className="top_imgs">
                      <img src={`img/items/item_${val.img1}.png`} alt="prv" />
                      <img src={`img/items/item_${val.img2}.png`} alt="prv" />
                      <img src={`img/items/item_${val.img3}.png`} alt="prv" />
                    </div> */}
                    <img src={val?.get("image")?.url()}/>
                  </Link>
                </div>
              </Link>
              <div className="collections_footer justify-content-between">
                <h5 className="collection_title">
                  <Link to={`/collection/${getPath(val?.get("name"))}`}>{val?.get("name")}</Link>
                </h5>
                <Link to="#" className="likes space-x-3">
                  <i className="ri-heart-3-fill" />
                  <span className="txt_md"></span>
                </Link>
              </div>
              <div className="creators space-x-10">
                <span className="color_text txt_md">
                   {val?.get("items").length} items · 
                </span>
                <div className="avatars space-x-5">
                {getUsers(val?.get("co_creators")).map((x, i) => (
                  <Link to={`user/${x?.get("ethAddress")}`}>
                    <img
                      src={x?.get("profilePic")?.url()}
                      className="avatar avatar-sm"
                    />
                  </Link>
                // <Link to="profile">
                //    <p className="avatars_name txt_sm"> @{} ... </p> 
                //  </Link> 
                ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CollectionProfile;
