import React from 'react';
import {Link} from 'react-router-dom';

const LeftMenu = [
    {
    icon: 'layout-grid',
    title: 'Colecciones',
    link: '/collections',
    },
    {
      icon: 'user',
      title: ' Creadores',
      link: '/creators',
    },
  {
    icon: 'edit',
    title: ' Mis Colecciones',
    link: '/manage-collection',
  },
  {
    icon: 'gallery',
    title: ' Crear Coleccion',
    link: '/create-collection',
  },
];

function Explore() {
  return (
    <div>
      <div className="row sub_menu_row">
        <div className="col-lg-6 space-y-10">
          {LeftMenu.map((val, i) => (
            <li key={i}>
              <Link to={val.link}>
                <i className={`ri-${val.icon}-line`} />
                {val.title}
              </Link>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Explore;
