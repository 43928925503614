import { ContactsOutlined } from "@ant-design/icons";
import { useMoralisDapp } from "../providers/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";
import { useIPFS } from "./useIPFS";
import { useMoralisCloudFunction } from "react-moralis";


// pass an NFT address and token id and return the NFT details

export const useNFTsDetailsAll = (NFTs) => {
  const { token } = useMoralisWeb3Api();
  const { chainId } = useMoralisDapp();
  const [NFTsDetails, setNFTsDetails] = useState([]);
  const { resolveLink } = useIPFS();
  const [fetchSuccess, setFetchSuccess] = useState(true);

  var array = [];
for (let i = 0; i < NFTs?.length; i++) {
    var address = NFTs[i].nftContract;
    var id = NFTs[i].tokenId;
    var chain = chainId;
    array.push(address + "/" + id + "/" + chain);
}

  const { data } = useMoralisCloudFunction(
    "getNFTsDetails",
    { NFTs: array },
  );



  useEffect(async () => {
    if (data) {
    for (let NFT of data) {
      setFetchSuccess(true);
      if (NFT?.metadata) {
        NFT.metadata = JSON.parse(NFT.metadata);
        if (NFT.metadata?.image){NFT.image = resolveLink(NFT.metadata?.image);}
        if (NFT.metadata?.animation_url){NFT.animation_url = resolveLink(NFT.metadata?.animation_url);}
      } else if (NFT?.token_uri) {
        try {
          await fetch(NFT.token_uri)
            .then((response) => response.json())
            .then((data) => {
              NFT.image = resolveLink(data.image);
            });
        } catch (error) {
          setFetchSuccess(false);
        }
      }
    }
    setNFTsDetails(data);
   }  
  }, [data]);



  return {
    // getNFTDetails,
    NFTsDetails,
    setNFTsDetails
    // fetchSuccess,
    // error,
    // isLoading,
  };
};




