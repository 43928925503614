import React from 'react';
import {Link} from 'react-router-dom';
import { useCollectionUser } from "../../hooks/useCollectionUser";
import { useUsersCollection } from "../../hooks/useUsersCollection";
import { useAccount } from 'wagmi';
import { useUser } from "../../hooks/useUser";

function ManageCollection() {
  const { address } = useAccount();
  const { User } = useUser(address);
  const user = User[0]
  const { Collections, fetchSuccess } = useCollectionUser(user?.get("ethAddress"));
  const { Users } = useUsersCollection(Collections);

  function getUsers(co_creators){
    var users = [];
    for (let i=0; i<co_creators.length && i<8; i++){
      for (let y of Users){
        if (y?.get("ethAddress") == co_creators[i].address.toLowerCase()){
          users.push(y);
        }
      }
    }
     return users;
  }

  function getPath(name){
    var path = name.toLowerCase();
    path = path.replace(/ /g, "-");
    return path;
  }

  return (
    <div>
      <div className="row mb-30_reset">
        {Collections.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-8" key={i}>
            <div className="collections space-y-10 mb-30">
              <Link to="/collections">
                <div className="collections_item">
                  <Link to={`/collection/${getPath(val?.get("name"))}`} className="images-box space-y-10">
                    <img src={val?.get("image")?.url()}/>
                    { val?.get("creatorAddress").toLowerCase() == user?.get("ethAddress").toLowerCase() ?
                    (
                    <div className="more-item">
                       <Link to={`/edit-collection/${getPath(val?.get("name"))}`} type="button" className="btn btn-white btn-sm">
                            Editar colección
                       </Link>
                     </div>
                    ) : (
                      <div className="more-item">
                        <Link to={`/edit-collection/${getPath(val?.get("name"))}`} type="button" className="btn btn-white btn-sm">
                            Gestionar NFTs
                         </Link>
                         </div>
                        )
                    }
                  </Link>
                </div>
              </Link>
              <div className="collections_footer justify-content-between">
                <h5 className="collection_title">
                  <Link to={`/collection/${getPath(val?.get("name"))}`}>{val?.get("name")}</Link>
                </h5>
              </div>
              <div className="creators space-x-10">
                <span className="color_text txt_md">
                   {val?.get("items").length} items · 
                </span>
                <div className="avatars space-x-5">
                {getUsers(val?.get("co_creators")).map((x, i) => (
                  <Link to={`/user/${x?.get("ethAddress")}`}>
                    <img
                      src={x?.get("profilePic")?.url()}
                      className="avatar avatar-sm"
                    />
                  </Link>
                // <Link to="/profile">
                //    <p className="avatars_name txt_sm"> @{} ... </p> 
                //  </Link> 
                ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManageCollection;
//   bybg5208938-1