import { Select } from 'antd';
import { useMoralisDapp } from "../../../providers/MoralisDappProvider/MoralisDappProvider";
import {Link} from 'react-router-dom';
import { useCollections } from "../../../hooks/useCollections";
import { useHistory } from 'react-router-dom';


function SearchCollections(){
    const { Option } = Select;
    const { chainId } = useMoralisDapp();
    const history = useHistory()
    
    const { Collections, fetchSuccess } = useCollections();
    
    function getPath(name){
        var path = name.toLowerCase();
        path = path.replace(/ /g, "-");
        return path;
      }

    function onChange(value) {
        history.push(`/collection/${getPath(value)}`);
    }

    return (
        <>
        {/* <div className="header__search">
            <input type="text" placeholder="Search" />
            <Link to="no-results" className="header__result">
                <i className="ri-search-line" />
            </Link>
        </div> */}

        <Select
            showSearch
            // style={{width: "300px",
            //         marginLeft: "20px" }}
            className='header__search'
            bordered={false}
            placeholder="Find a Collection"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            onChange={onChange}
        >   
        {Collections && 
            Collections.map((collection, i) => 
            <Option value={collection.get("name")} key= {i}>
                    {collection.get("name")}
            </Option>
            )
            }   
        </Select>
            
        </>
    )
}
export default SearchCollections;