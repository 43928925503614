import React from 'react';
import {Link} from 'react-router-dom';
import { useCollectionUser } from "../../hooks/useCollectionUser";
import { useCreatorCollection } from "../../hooks/useCreatorCollection";
import { useMoralis } from "react-moralis";


function ProyectosUser({address}) {
  const { Collections, fetchSuccess } = useCollectionUser(address); // get the collections of this address
  const { Users } = useCreatorCollection(Collections); // get the creators (projects) of this collections


  return (
    <div>
      <div className="row mb-30_reset">
        {Users.map((val, i) => (
            val?.get("isProject") && (
                <div className="col-lg-4 col-md-6 col-sm-8" key={i}>
                    <div className="collections space-y-10 mb-30">
                    <Link to="collections">
                        <div className="collections_item">
                        <Link to={`/user/${val.get("ethAddress")}`} className="images-box space-y-10">
                            <img src={val?.get("profilePic")?.url()}/>
                        </Link>
                        </div>
                    </Link>
                    <div className="collections_footer justify-content-between">
                        <h5 className="collection_title">
                        <Link to={`/user/${val.get("ethAddress")}`}>{val.get("username")}</Link>
                        </h5>
                        <Link to="#" className="likes space-x-3">
                        <i className="ri-heart-3-fill" />
                        <span className="txt_md"></span>
                        </Link>
                    </div>
                    </div>
                </div>
            )
        ))}
      </div>
    </div>
  );
}

export default ProyectosUser;
