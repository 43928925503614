import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";

export const useCollections = (pageSize = 20) => {
  const [Collections, setCollections] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    // fetch: getNFTDetails,
    data,
    // error,
    // Reference: https://www.npmjs.com/package/react-moralis#usemoralisquery
  } = useMoralisQuery("Collection", query => query.descending('updatedAt').skip(page * pageSize).limit(pageSize), [page]);

  const isLastPage = data && data.length < pageSize;

  const nextPage = () => {
    if (!isLastPage) {
      setPage(page => page + 1);
      setIsLoading(true);
    }
  };

  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      setCollections(prev => [...prev, ...data]);
      //console.log(data)
    }
    setIsLoading(false);
  }, [data]);



  return {
    Collections,
    fetchSuccess,
    isLoading,
    nextPage,
    isLastPage,
  };
};


//   bybg52089381