import { useEffect, useState } from "react";
import { useMoralisQuery, useMoralis, useMoralisFile } from "react-moralis";
import { useMoralisCloudFunction } from "react-moralis";

// given an array of Collections, return array of Users who are part of those Collections

export const useUsersCollection = (Collections) => {
  const [Users, setUsers] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const { Moralis} = useMoralis();
  var addresses = [];

  for (let val of Collections){
    if (val != undefined){
      var co_creators = val.get("co_creators")
      for (let creator of co_creators){
        if (!addresses.includes(creator.address.toLowerCase())){
          addresses.push(creator.address.toLowerCase());
        }
      }
    }
  }
  
  const { data } = useMoralisCloudFunction(
    "getUsers",
    { addresses: addresses }
  );

  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      const res =[];
      for (let x of data){
        if (x[0] != undefined){
          res.push(x[0]);
        }
      }
      for (let i of addresses){
        if (!res.find(e => e?.get("ethAddress").toLowerCase() == i.toLowerCase())){
          const user = new Moralis.Object('_User');
          user.set('ethAddress', i.toLowerCase());
          user.set('username', i.toLowerCase());
          user.set('description', "Unknown");
          res.push(user);
        }
      }
      setUsers(res);
    }
  }, [data]);



  return {
    // getNFTDetails,
    Users,
    fetchSuccess,
    // error,
    // isLoading,
  };
};