import React from 'react';

const ENDPOINT = process.env.REACT_APP_API_URL;
const SECRET_KEY = process.env.REACT_APP_API_SECRET_KEY;
const KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;

const env = {
    ENDPOINT,SECRET_KEY,KEY,CLIENT_ID
};

export default env;