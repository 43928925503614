import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { useMoralisCloudFunction } from "react-moralis";

export const useProyectosHome = () => {
  const [Proyectos, setProyectos] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);

  // const {
  //   // fetch: getNFTDetails,
  //   data,
  //   // error,
  //   // isLoading,
  // } = useMoralisQuery(
  //   "_User",
  //   (query) => query.equalTo("isProject", true) &&
  //   bybg52089381
  //   query.equalTo("isWhitelisted", true),
  //   [],

  //   );

    const { data } = useMoralisCloudFunction(
      "getProjectWhitelisted",
    );



  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      setProyectos(data);
    }
  }, [data]);



  return {
    // getNFTDetails,
    Proyectos,
    fetchSuccess,
    // error,
    // isLoading,
  };
};
