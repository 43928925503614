// TESTING VERSION 

import React from "react";
import Preview from "../views/Preview";
import "antd/dist/antd.css";
// Some Modal styles are imported from antd BUT other styles from antd may be broke the styles of this react app,
// if that happens please comment "antd/dist/antd.css"

// All HOME PAGE ROUTES
import Home from "../views/homes/Home"
import Home2 from "../views/homes/Home2"
import Home3 from "../views/homes/Home3"

// Account inner pages
import ConnectWalllet from "../views/pages/account/ConnectWalllet"
import EditProfile from "../views/pages/account/EditProfile"
import Login from "../views/pages/account/Login"
import Profile from "../views/pages/account/Profile"
import Register from "../views/pages/account/Register"
import User from "../views/pages/account/User"

//  NftPages
import ItemDetails from "../views/pages/item/ItemDetails"
import Upload from "../views/pages/item/Upload"
import UploadMultiple from "../views/pages/item/UploadMultiple"
import UploadType from "../views/pages/item/UploadType"
import Marketplace from "../views/pages/NftPages/Marketplace"

// Collection pages
import Collections from "../views/pages/NftPages/Collections"
import Collection from "../views/pages/NftPages/Collection"
import CreateCollection from "../views/pages/item/CreateCollection";
import ManageCollections from "../views/pages/item/ManageCollections";
import EditCollection from "../views/pages/item/EditCollection";

// Blog inner pages
import Blog from "../views/pages/blog/Blog"
import Article from "../views/pages/blog/Article"

// Other pages
import Faq from "../views/pages/Support/Faq"
import UpcomingProjects from "../views/pages/NftPages/UpcomingProjects"
import Proyectos from "../views/pages/NftPages/Proyectos"
import NotFound from "../views/NotFound"
import NoResults from "../views/pages/others/NoResults"
import Creators from "../views/pages/NftPages/Creators"
import LiveAuctions from "../views/pages/NftPages/LiveAuctions"
import Ranking from "../views/pages/NftPages/Ranking"
import Activity from "../views/pages/others/Activity"
import Newsletter from "../views/pages/others/Newsletter"
import PrivacyPolicy from "../views/pages/others/PrivacyPolicy"
import Chat from "../views/pages/Support/Chat"
import SubmitRequest from "../views/pages/Support/SubmitRequest"
import Forum from "../views/pages/forum/Forum"
import PostDetails from "../views/pages/forum/PostDetails"
import Contact from "../views/pages/Support/Contact"

import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useAccount } from "wagmi";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
const Routes = () => {

const { isWeb3Enabled, enableWeb3, isWeb3EnableLoading } = useMoralis();
const { isConnected: isAuthenticated } = useAccount();

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
  }, [isAuthenticated, isWeb3Enabled]);

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/user/:address" component={User} />
                    <Route path="/edit-profile" component={EditProfile} />
                    <Route path="/upload-type" component={UploadType} />
                    <Route path="/upload" component={Upload} />
                    <Route path="/upload-multiple" component={UploadMultiple} />
                    <Route exact path="/create-collection" component={CreateCollection} />
                    <Route path="/manage-collection" component={ManageCollections} />
                    <Route exact path="/edit-collection/:name" component={EditCollection} />
                    <Route path="/collections" component={Collections} />
                    <Route path="/collection/:name" component={Collection} />
                    <Route path="/proyectos" component={Proyectos} />
                    <Route path="/item-details/:address/:id/:projectNameNode" component={ItemDetails} />
                    <Route path="/item-details/:address/:id" component={ItemDetails} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/home" component={Home} />
                    <Route path="/creators" component={Creators} />
                    <Route path="/marketplace" component={Marketplace} />
                    <Route path="/no-results" component={NoResults} />
                    <Route component={NotFound} />
                    {/* <Route path="/upcoming-projects" component={UpcomingProjects} /> */}
                    {/* inner pages */}
                    {/* <Route path="/home-2" component={Home2} />
                    <Route path="/home-3" component={Home3} />  
                    <Route path="/connect-wallet" component={ConnectWalllet} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/blog" component={Blog} />
                    <Route path="/article" component={Article} />
                    <Route path="/creators" component={Creators} />
                    <Route path="/live-auctions" component={LiveAuctions} />
                    <Route path="/ranking" component={Ranking} />
                    <Route path="/activity" component={Activity} />
                    <Route path="/newsletter" component={Newsletter} />
                    <Route path="/chat" component={Chat} />
                    <Route path="/submit-request" component={SubmitRequest} /> 
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/forum" component={Forum} />
                    <Route path="/post-details" component={PostDetails} />
                    <Route path="/contact" component={Contact} /> */}

                </Switch>
            </Router>
        </>
    );
};

export default Routes;
