import { useEffect, useState } from "react";
import { useMoralisQuery } from "react-moralis";
import { useMoralisCloudFunction } from "react-moralis";

// given an array of Collections, return array of Users who are the creators of those Collections

export const useCreatorCollection = (Collections) => {
  const [Users, setUsers] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  var addresses = [];

  for (let val of Collections){
    if (val != undefined){
      var co_creators = val.get("co_creators")
      for (let creator of co_creators){
        if (!addresses.includes(creator.address.toLowerCase())){
          addresses.push(creator.address.toLowerCase());
        }
      }
    }
  }
  
    const { data } = useMoralisCloudFunction(
      "getUsers",
      { addresses: addresses }
    );



  useEffect(async () => {
    if (data) {
      setFetchSuccess(true);
      const res =[];
      for (let x of data){
        res.push(x[0]);
      }
      setUsers(res);
    }
  }, [data]);



  return {
    // getNFTDetails,
    Users,
    fetchSuccess,
    // error,
    // isLoading,
  };
};


